<template>
  <div class="table-responsive mb-0">
    <table class="table">
      <thead>
        <tr>
          <th>
            {{ $t("views.companies.economy.data_management.show.filename") }}
          </th>

          <th class="col-shrink"></th>
        </tr>
      </thead>

      <tbody>
        <tr v-if="allowedExports.includes('fullExport')">
          <td>
            {{
              $t(
                "views.companies.economy.data_management.show.export.download_variables"
              )
            }}
          </td>

          <td>
            <financials-export-button variant="fullExport" size="sm" />
          </td>
        </tr>

        <tr v-if="allowedExports.includes('kpiExport')">
          <td>
            {{
              $t(
                "views.companies.economy.data_management.show.export.download_kpi_template"
              )
            }}
          </td>

          <td>
            <financials-export-button variant="kpiExport" size="sm" />
          </td>
        </tr>

        <tr v-if="allowedExports.includes('resultReportAndBalanceExport')">
          <td>
            {{
              $t(
                "views.companies.economy.data_management.show.export.download_result_and_balance"
              )
            }}
          </td>

          <td>
            <financials-export-button
              variant="resultReportAndBalanceExport"
              size="sm"
            />
          </td>
        </tr>

        <tr v-if="allowedExports.includes('tableExport')">
          <td>
            {{
              $t(
                "views.companies.economy.data_management.show.export.export_tables"
              )
            }}
          </td>

          <td>
            <financials-export-button variant="tableExport" size="sm" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
const DEFAULT_ALLOWED_EXPORTS = [
  "fullExport",
  "kpiExport",
  "resultReportAndBalanceExport",
  "tableExport",
];

import FinancialsExportButton from "@/components/financials/editors/FinancialsExportButton.vue";

export default {
  components: {
    FinancialsExportButton,
  },

  props: {
    allowedExports: {
      type: Array,
      required: false,
      default: () => DEFAULT_ALLOWED_EXPORTS,
    },
  },

  created() {
    // Do not fetch integrations for AdminPanel
    if (this.$currentCompany?.id) {
      this.$store.dispatch("company/fetchEnabledIntegrations");
    }
  },
};
</script>
