<template>
  <be-modal :id="modalId" :title="modalTitle" size="lg" @hidden="reset">
    <div class="d-flex flex-column mb-4">
      <slot name="description" />

      <be-table
        :items="sortedInvitations"
        :fields="fields"
        :per-page="30"
        sort-by="created_at"
        @row-clicked="(item) => toggleChecked(item)"
      >
        <template #avatar="{ item }">
          <user-avatar :user="getUser(item.user_id)" />
        </template>

        <template #name="{ item }">
          {{ getUserName(item.user_id) }}

          <membership-subtitle class="small" :user-id="item.user_id" />
        </template>

        <template #email="data">
          <be-link :href="`mailto:${getUserEmail(data.item.user_id)}`">
            {{ getUserEmail(data.item.user_id) }}
          </be-link>
        </template>

        <template #created_at="data">
          <template v-if="!data.item.deleted_at && data.item.created_at">
            {{ $d(new Date(data.item.created_at), { format: "mini" }) }}
          </template>
        </template>

        <template #options="data">
          <be-form-checkbox
            :id="`checked-${data.item.user_id}`"
            :checked="isUserIdChecked(data.item.user_id)"
            :name="`checked-${data.item.user_id}`"
            @change="toggleChecked(data.item)"
          >
            <span class="sr-only">
              {{ $t("components.companies.invitations.check_to_invite") }}
            </span>
          </be-form-checkbox>
        </template>
      </be-table>

      <div v-if="!locked" class="mt-2 d-md-flex justify-content-start">
        <div class="mr-md-2">
          <be-button
            v-if="!isEveryoneChecked"
            variant="outline-secondary"
            :disabled="invitations.length == 0"
            @click="checkEveryone"
          >
            {{ $t("buttons.toggle_all_selection.select_all") }}
          </be-button>

          <be-button
            v-else
            variant="outline-secondary"
            :disabled="invitations.length == 0"
            @click="uncheckEveryone"
          >
            {{ $t("buttons.toggle_all_selection.deselect_all") }}
          </be-button>
        </div>
      </div>
    </div>

    <be-form-group
      label-for="invitation-message"
      :label="$t('activerecord.attributes.invitation.message')"
      :description="$t('models.membership.hints.invitation_message')"
    >
      <be-form-textarea
        id="invitation-message"
        v-model="message"
        rows="3"
        max-rows="6"
      />
    </be-form-group>

    <slot name="options" />

    <template #footer="{ cancel }">
      <be-button variant="light" @click="cancel">
        {{ $t("buttons.titles.cancel") }}
      </be-button>

      <be-button
        :disabled="!allowEmpty && selectedInvitations.length === 0"
        :loading="loading"
        variant="primary"
        icon="fa-envelope"
        @click="sendInvites"
      >
        {{ localModalConfirm }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
import MembershipSubtitle from "@/components/shared/MembershipSubtitle.vue";

export default {
  components: {
    MembershipSubtitle,
  },

  props: {
    invitations: {
      type: Array,
      required: true,
    },

    modalId: {
      type: String,
      required: true,
    },

    modalTitle: {
      type: String,
      required: true,
    },

    modalConfirm: {
      type: String,
      required: false,
      default: null,
    },

    checkedIds: {
      type: Array,
      required: false,
      default: () => [],
    },

    locked: {
      type: Boolean,
      required: false,
      default: false,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    allowEmpty: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["send", "update:message"],

  data() {
    return {
      message: "",
      checkedUserIds: this.initializeCheckedUserIds(),
    };
  },

  computed: {
    isEveryoneChecked() {
      return this.invitations.every((invitation) =>
        this.isUserIdChecked(invitation.user_id)
      );
    },

    selectedInvitations() {
      return this.invitations.filter((invitation) =>
        this.isUserIdChecked(invitation.user_id)
      );
    },

    sortedInvitations() {
      let checked = [];
      let others = [];

      this.invitations.forEach((invitation) => {
        if (this.checkedIds.includes(invitation.user_id)) {
          checked.push(invitation);
        } else {
          others.push(invitation);
        }
      });

      return checked.concat(others);
    },

    localModalConfirm() {
      return this.modalConfirm || this.$t("buttons.titles.invite");
    },

    fields() {
      const fields = [
        {
          key: "avatar",
          label: "",
          class: "col-shrink",
        },
        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },
        {
          key: "email",
          label: this.translateAttribute("user", "email"),
        },
        {
          key: "created_at",
          label: this.translateAttribute("invitation", "created_at"),
          class: "col-shrink",
        },
      ];

      if (!this.locked) {
        fields.unshift({
          key: "options",
          label: "",
          class: "text-center col-shrink",
        });
      }
      return fields;
    },
  },

  watch: {
    invitations: {
      handler() {
        this.checkedUserIds = this.initializeCheckedUserIds();
      },

      deep: true,
    },

    locked() {
      this.checkedUserIds = this.initializeCheckedUserIds();
    },

    checkedIds: {
      handler() {
        this.checkedUserIds = this.initializeCheckedUserIds();
      },

      deep: true,
    },

    message() {
      this.$emit("update:message", this.message);
    },
  },

  methods: {
    initializeCheckedUserIds() {
      if (this.locked) {
        return this.invitations.map((invitation) => invitation.user_id);
      } else {
        return this.cloneDeep(this.checkedIds);
      }
    },

    toggleChecked(invitation) {
      const userId = invitation.user_id;

      const index = this.checkedUserIds.indexOf(userId);
      if (index > -1) {
        this.checkedUserIds.splice(index, 1);
      } else {
        this.checkedUserIds.push(userId);
      }
    },

    isUserIdChecked(userId) {
      return this.checkedUserIds.includes(userId);
    },

    checkEveryone() {
      this.checkedUserIds = this.invitations.map(
        (invitation) => invitation.user_id
      );
    },

    uncheckEveryone() {
      this.checkedUserIds = [];
    },

    sendInvites() {
      this.$emit("send", {
        invitations: this.selectedInvitations,
        message: this.message,
      });
    },

    reset() {
      this.initializeCheckedUserIds();
      this.message = "";
    },
  },
};
</script>
