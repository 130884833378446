<template>
  <div>
    <vue-date-picker
      v-bind="datepickerConfig"
      ref="datepicker"
      :model-value="date"
      @blur="onBlur"
      @cleared="onCleared"
      @close="onClose"
      @focus="onFocus"
      @open="onOpen"
      @update:model-value="onModelValueUpdate"
    >
      <!-- Replace default icons -->
      <!--
      This is done as a loop to decrease code instead of having
      to manually define each of the icons, adding a lot of mark up.
    -->
      <template
        v-for="icon in Object.keys(customIcons)"
        #[icon]="{ clear }"
        :key="icon"
      >
        <i
          v-be-tooltip="
            customIcons[icon].tooltip ? customIcons[icon].tooltip : null
          "
          :class="customIcons[icon].icon"
          @click="customIcons[icon].scope ? clear() : null"
        />

        <span v-if="customIcons[icon].label" class="ml-2 small text-muted">
          {{ customIcons[icon].label }}
        </span>
      </template>

      <!-- Customized action buttons -->
      <template #action-buttons>
        <div class="d-flex gap-2 w-100">
          <be-button
            v-if="clearable"
            variant="light"
            size="sm"
            block
            @click="clearDatepicker"
          >
            {{ $t("buttons.titles.clear") }}
          </be-button>

          <be-button
            v-if="showSelectTodayButton"
            variant="outline-primary"
            size="sm"
            block
            @click="selectToday"
          >
            {{
              type === "time"
                ? $t("components.shared.be_form_datepicker.now")
                : $t("components.shared.be_form_datepicker.today")
            }}
          </be-button>

          <be-button variant="primary" size="sm" block @click="selectDate">
            {{ $t("buttons.titles.select") }}
          </be-button>
        </div>
      </template>

      <!-- Add helper action buttons -->
      <template v-if="showLeftSidebar" #left-sidebar>
        <div class="d-flex gap-2">
          <be-button
            v-if="clearable"
            variant="light"
            size="sm"
            block
            @click="clearDatepicker"
          >
            {{ $t("buttons.titles.clear") }}
          </be-button>

          <be-button
            variant="outline-primary"
            size="sm"
            block
            @click="selectToday"
          >
            {{ $t("components.shared.be_form_datepicker.today") }}
          </be-button>
        </div>
      </template>

      <!-- This slot is used to replace the default trigger element (input), if needed -->
      <template v-if="$slots.trigger" #trigger>
        <slot name="trigger" />
      </template>
    </vue-date-picker>

    <!-- Hidden input field to store the formatted date in ISO-8601 format -->
    <input
      v-if="name"
      type="hidden"
      :name="name"
      :value="formatDate(date, 'sv-SE')"
    />
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { generateId } from "@/utils/id";
import dateUtils from "@/utils/date";
import formStateMixin from "@/mixins/forms/form-state";

// Supported datepicker types
const availableTypes = [
  "date",
  "range",
  "datetime",
  "time",
  "week",
  "month",
  "quarter",
  "year",
];

export default {
  name: "BeFormDatepicker",

  components: {
    VueDatePicker,
  },

  mixins: [formStateMixin],

  props: {
    calendarInline: {
      type: Boolean,
      required: false,
      default: false,
    },

    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("be-form-datepicker"),
    },

    inline: {
      type: Boolean,
      required: false,
      default: false,
    },

    modelValue: {
      type: [Date, String, Array, Number, Object],
      required: false,
      default: undefined,
    },

    multiDates: {
      type: Boolean,
      required: false,
      default: false,
    },

    name: {
      type: String,
      required: false,
      default: null,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    timeInline: {
      type: Boolean,
      required: false,
      default: false,
    },

    type: {
      type: String,
      required: false,
      default: "date",

      validator: (value) => availableTypes.includes(value),
    },

    unstyledWrapper: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: [
    "blur",
    "change",
    "cleared",
    "close",
    "focus",
    "input",
    "open",
    "update:modelValue",
    "update:startDate",
    "update:endDate",
  ],

  data() {
    return {
      date: this.modelValue,
    };
  },

  computed: {
    beGroup() {
      if (!this.isGroup) {
        return null;
      }

      // Check if the parent is a `BeFormGroup` component
      if (this.$parent && this.$parent.$options.name === "BeFormGroup") {
        return this.$parent;
      } else if (
        // Check if the parent's parent is a `BeFormGroup` component
        this.$parent.$parent &&
        this.$parent.$parent.$options.name === "BeFormGroup"
      ) {
        return this.$parent.$parent;
      } else {
        return null;
      }
    },

    customIcons() {
      // https://vue3datepicker.com/slots/icons/
      return {
        "input-icon": {
          icon:
            this.type === "time"
              ? "fal fa-clock fa-lg"
              : "fal fa-calendar fa-lg",
        },

        "clock-icon": {
          icon: "far fa-arrow-left",

          label: this.$t(
            "components.shared.be_form_datepicker.back_to_time_picker"
          ),
        },

        "arrow-up": {
          icon: "far fa-chevron-up fa-sm",
        },

        "arrow-down": {
          icon: "far fa-chevron-down fa-sm",
        },

        "arrow-left": {
          icon: "far fa-arrow-left p-1",

          tooltip: this.$t(
            "components.shared.be_form_datepicker.aria_labels.prev_month"
          ),
        },

        "arrow-right": {
          icon: "far fa-arrow-right p-1",

          tooltip: this.$t(
            "components.shared.be_form_datepicker.aria_labels.next_month"
          ),
        },

        "calendar-icon": {
          icon: "far fa-arrow-left",

          label: this.$t(
            "components.shared.be_form_datepicker.back_to_calendar"
          ),
        },

        // Commented out as a workaround until known issue in vue3-datepicker is fixed
        // See: https://github.com/Vuepic/vue-datepicker/issues/945
        // "clear-icon": {
        //   icon: "fas fa-times p-1",
        //   tooltip: this.$t("buttons.titles.clear"),
        //   scope: true,
        // },
      };
    },

    computedClasses() {
      return [
        {
          // These two are mainly used to identify the datepickers
          // in system tests
          "be-form-datepicker": this.type !== "time",
          "be-form-timepicker": this.type === "time",

          "d-flex d-lg-inline-flex w-auto":
            this.inline || this.calendarInline || this.timeInline,

          "unstyled-wrapper":
            this.unstyledWrapper && (this.calendarInline || this.timeInline),
        },
      ];
    },

    computedInputClasses() {
      return [
        "form-control",
        this.stateClass,
        ...(this.clearable ? ["clearable"] : []),
      ];
    },

    computedPlaceholder() {
      if (availableTypes.includes(this.type)) {
        return this.$t(
          `components.shared.be_form_datepicker.placeholders.${this.type}`
        );
      } else {
        return this.$t(
          "components.shared.be_form_datepicker.placeholders.date"
        );
      }
    },

    computedRequired() {
      if (this.required) {
        return this.required;
      } else if (this.isGroup && this.beGroup) {
        return this.beGroup.localRequired;
      } else {
        return false;
      }
    },

    computedType() {
      const typeMapping = {
        range: "range",
        month: "month-picker",
        time: "time-picker",
        year: "year-picker",
        week: "week-picker",
        quarter: "quarter-picker",
        "datetime-range": "range",
      };

      return typeMapping[this.type] || null;
    },

    datepickerConfig() {
      // More configuration options can be found here:
      // https://vue3datepicker.com/

      return {
        // General attributes
        uid: this.id,
        locale: this.locale,
        is24: this.locale !== "en-US",
        format: this.formatDate,
        monthNameFormat: "long",
        position: "center",
        inputClassName: this.computedInputClasses.join(" "),
        [this.computedType]: true,
        multiDates: this.multiDates,
        inline: this.calendarInline || this.timeInline,
        disabled: this.disabled,
        clearable: this.clearable,

        // Classes
        class: this.computedClasses,

        // Placeholder text for the input field
        placeholder: this.computedPlaceholder,

        // Apply the clicked date immediately
        autoApply:
          (this.type === "date" && !this.multiDates) ||
          ["week", "month", "quarter", "year"].includes(this.type),

        // Show the time picker
        enableTimePicker: this.type === "datetime",

        // Teleports the datepicker to the body, which is necessary
        // when the datepicker is used inside a container with overflow: hidden
        teleport: true,

        // Render the time picker inline under the calendar
        timePickerInline: this.type !== "time",

        // Set the start date to the current date
        startDate: new Date(),

        // Config object for the date picker
        config: {
          // Set the height for the time picker menu, 255px is the default
          modeHeight: this.type === "time" ? 100 : 255,
        },

        // Locale labels
        ...this.localeLabels,

        // Attributes that are set on the component that should
        // override the default values set in the component
        ...this.$attrs,

        // Reset the `name` attribute so it is not set on the datepicker input.
        // We handle it manually with a hidden input field.
        name: null,
      };
    },

    isGroup() {
      // Check if the parent is a `BeFormGroup` component
      if (this.$parent && this.$parent.$options.name === "BeFormGroup") {
        return true;
      } else if (
        // Check if the parent's parent is a `BeFormGroup` component
        this.$parent.$parent &&
        this.$parent.$parent.$options.name === "BeFormGroup"
      ) {
        return true;
      } else {
        return false;
      }
    },

    locale() {
      const locale = this.$i18n.locale;

      switch (locale) {
        case "en":
          return "en-US";
        default:
          return "sv-SE";
      }
    },

    localeLabels() {
      return {
        selectText: this.$t("buttons.titles.select"),
        cancelText: this.$t("buttons.titles.cancel"),
        nowButtonLabel: this.$t("components.shared.be_form_datepicker.now"),

        ariaLabels: {
          toggleOverlay: this.$t(
            "components.shared.be_form_datepicker.aria_labels.toggle_overlay"
          ),

          menu: this.$t(
            "components.shared.be_form_datepicker.aria_labels.menu"
          ),

          input: this.$t(
            "components.shared.be_form_datepicker.aria_labels.input"
          ),

          openTimePicker: this.$t(
            "components.shared.be_form_datepicker.aria_labels.open_time_picker"
          ),

          closeTimePicker: this.$t(
            "components.shared.be_form_datepicker.aria_labels.close_time_picker"
          ),

          incrementValue: (type) =>
            this.$t(
              "components.shared.be_form_datepicker.aria_labels.increment_value",
              {
                type: this.$t(
                  `components.shared.be_form_datepicker.incremental_types.${type}`
                ),
              }
            ),

          decrementValue: (type) =>
            this.$t(
              "components.shared.be_form_datepicker.aria_labels.decrement_value",
              {
                type: this.$t(
                  `components.shared.be_form_datepicker.incremental_types.${type}`
                ),
              }
            ),

          openTpOverlay: (type) => {
            return this.$t(
              "components.shared.be_form_datepicker.aria_labels.open_tp_overlay",
              {
                type: this.$t(
                  `components.shared.be_form_datepicker.incremental_types.${type}`
                ),
              }
            );
          },

          amPmButton: this.$t(
            "components.shared.be_form_datepicker.aria_labels.am_pm_button"
          ),

          openYearsOverlay: this.$t(
            "components.shared.be_form_datepicker.aria_labels.open_years_overlay"
          ),

          openMonthsOverlay: this.$t(
            "components.shared.be_form_datepicker.aria_labels.open_months_overlay"
          ),

          nextMonth: this.$t(
            "components.shared.be_form_datepicker.aria_labels.next_month"
          ),

          prevMonth: this.$t(
            "components.shared.be_form_datepicker.aria_labels.prev_month"
          ),

          nextYear: this.$t(
            "components.shared.be_form_datepicker.aria_labels.next_year"
          ),

          prevYear: this.$t(
            "components.shared.be_form_datepicker.aria_labels.prev_year"
          ),

          day: (date) => {
            return dateUtils.formatDate(date.value, "sv"); // We want the "2020-01-01" format here
          },
        },
      };
    },

    showLeftSidebar() {
      if (this.type === "date" && !this.multiDates) {
        return true;
      } else {
        return false;
      }
    },

    showSelectTodayButton() {
      return !this.multiDates && ["date", "datetime"].includes(this.type);
    },
  },

  watch: {
    modelValue: {
      handler(newValue) {
        this.date = newValue;
      },

      deep: true,
    },
  },

  mounted() {
    this.setWidth();
  },

  methods: {
    clearDatepicker() {
      if (this.type === "range") {
        this.date = [];
        this.$emit("update:modelValue", []);
        this.$emit("update:startDate", null);
        this.$emit("update:endDate", null);
      } else {
        this.date = null;
        this.$emit("update:modelValue", null);
      }
    },

    formatDate(date, locale) {
      if (!date) {
        return "";
      }

      if (!locale) {
        locale = this.locale;
      }

      const formatFunctions = {
        range: (date, locale) =>
          dateUtils.formatDateRange(date[0], date[1], locale),

        datetime: (date, locale) => dateUtils.formatDateTime(date, locale),
        time: (date, locale) => dateUtils.formatTime(date, locale),
        week: (date, locale) => dateUtils.formatWeek(date, locale),
        month: (date, locale) => dateUtils.formatMonth(date, locale),
        quarter: (date) => dateUtils.formatQuarter(date),
        year: (date, locale) => dateUtils.formatYear(date, locale),

        default: (date, locale, multi) => {
          if (multi) {
            return date.map((d) => dateUtils.formatDate(d, locale)).join(", ");
          } else {
            return dateUtils.formatDate(date, locale);
          }
        },
      };

      const formatFunction =
        formatFunctions[this.type] || formatFunctions.default;
      return formatFunction(date, locale, this.multiDates);
    },

    onBlur() {
      this.$emit("blur");
    },

    onCleared() {
      this.$emit("cleared");
    },

    onClose() {
      this.$emit("close");
    },

    onFocus() {
      this.$emit("focus");
    },

    onOpen() {
      this.$emit("open");
    },

    onModelValueUpdate(value) {
      // Set the time to midnight for range types, as the time
      // is not needed for these types and it makes comparing and
      // filtering easier.
      if (this.type === "range") {
        value = this.setTimeToMidnight(value);
      }

      this.date = value;
      this.$emit("update:modelValue", value);
      this.$emit("input", value);
      this.$emit("change", value);

      if (this.type === "range") {
        const startDate = value && value[0] ? value[0] : null;
        const endDate = value && value[1] ? value[1] : null;

        this.$emit("update:startDate", startDate);
        this.$emit("update:endDate", endDate);
      }

      this.setWidth();
    },

    selectDate() {
      this.$refs.datepicker.selectDate();
    },

    selectToday() {
      if (this.type === "time") {
        this.date = {
          hours: new Date().getHours(),
          minutes: new Date().getMinutes(),
        };
      } else {
        this.date = new Date();
      }

      this.$refs.datepicker.closeMenu();
      this.$emit("update:modelValue", this.date);
      this.$emit("input", this.date);
      this.$emit("change", this.date);

      this.setWidth();
    },

    setTimeToMidnight(date) {
      if (Array.isArray(date)) {
        return date.map((d) => dateUtils.setTimeToMidnight(d));
      } else {
        return dateUtils.setTimeToMidnight(date);
      }
    },

    // This sets the width of the input field to the length of the formatted
    // date string, if the `inline` prop is passed, by using the native `size`
    // attribute on the input field.
    setWidth() {
      if (!this.inline) {
        return;
      }

      let date = this.date;

      if (typeof date === "string") {
        date = new Date(date);
      } else if (typeof date === "number") {
        date = dateUtils.parseDateNumber(date);
      } else if (!Array.isArray(date) && typeof date === "object") {
        date = dateUtils.parseDateObject(date);
      }

      const size = this.formatDate(date).length;
      const placeholderSize = this.clearable
        ? this.computedPlaceholder.length
        : 0;
      const input = this.$refs.datepicker.$el.querySelector("input");

      if (input) {
        input.setAttribute("size", size || placeholderSize);
      }
    },
  },
};
</script>
