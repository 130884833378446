<template>
  <div>
    <input
      v-bind="computedAttrs"
      ref="input"
      type="tel"
      :class="computedClass"
      :value="localValue"
      @input="handleInput"
      @change="handleChange"
      @focus="onFocus"
      @keyup="onKeyup"
      @keydown="onKeydown"
      @paste="onPaste"
    />

    <!-- This is only used in ERB forms -->
    <small v-if="localError" class="text-danger mt-2">
      {{ localError }}
    </small>
  </div>
</template>

<script>
import intlTelInput from "intl-tel-input";
import formStateMixin from "@/mixins/forms/form-state";
import formTextMixin from "@/mixins/forms/form-text";
import { generateId } from "@/utils/id";

// Import version from package.json file and use it when defining
// the CDN URL for the intlTelInput utils script.
// NOTE: We can probably improve this by copying the utils script
// from the node_modules folder to the public folder and then
// import it from there instead.
import pkg from "intl-tel-input/package.json";
const INTL_TEL_INPUT_VERSION = pkg.version;
const INTL_TEL_INPUT_UTILS_SCRIPT_URL = `https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/${INTL_TEL_INPUT_VERSION}/js/utils.min.js`;

export default {
  name: "BeTelInput",

  mixins: [formStateMixin, formTextMixin],

  props: {
    // This is used for ERB forms
    hiddenInput: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,

      default: () => {
        return generateId("be-tel-input");
      },
    },

    modelValue: {
      type: [String, Number],
      required: false,
      default: "",
    },
  },

  emits: ["input", "change", "update:modelValue"],

  data() {
    return {
      ignoreNextInput: false,
      localError: null,
      telInput: null,
    };
  },

  computed: {
    computedAttrs() {
      const {
        id,
        name,
        form,
        disabled,
        autocomplete,
        readonly,
        plaintext,
        required,
        computedAriaInvalid,
        computedRequired,
      } = this;

      return {
        id,
        name,
        form,
        disabled: disabled || null,
        required: computedRequired || null,
        autocomplete: autocomplete || null,
        readonly: readonly || plaintext || null,
        "aria-required": required ? "true" : null,
        "aria-invalid": computedAriaInvalid || null,
      };
    },
  },

  watch: {
    modelValue(newValue) {
      this.localValue = String(newValue);
      this.telInput?.setNumber(String(newValue) || "");
    },
  },

  mounted() {
    // Initialize the intlTelInput plugin after the DOM has been updated
    this.$nextTick(() => {
      this.initTelInput();
    });
  },

  methods: {
    initTelInput() {
      const { input } = this.$refs;

      // Check if the input element exists, otherwise log an error to Sentry
      if (!input) {
        this.handleError(
          new Error(this.$el, "BeTelInput: The input element was not found.")
        );

        return;
      }

      // Initialize the intlTelInput plugin
      this.telInput = intlTelInput(input, {
        autoPlaceholder: "off",
        initialCountry: "se",

        // Creates a hidden input with the same name as the tel input,
        // which can be used in ERB forms to get the full international
        // phone number value. It also creates a hidden input with the
        // country code, which can be used to get the country code value.
        // If the tel input name ends in brackets, e.g. "user[phone]",
        // we need to manually define the correct hidden input name by
        // returning an object and appending the "_country" suffix inside
        // the brackets, otherwise Rails will not be able to parse the
        // params correctly.
        hiddenInput: this.hiddenInput
          ? function (telInputName) {
              // Check if the name ends in brackets
              if (telInputName.endsWith("]")) {
                return {
                  // Append "_country" to the name inside the last brackets
                  country: telInputName.replace(
                    /(\[[^\]]+)(\])$/,
                    `$1_country$2`
                  ),

                  phone: telInputName,
                };
              } else {
                return telInputName;
              }
            }
          : null,

        utilsScript: INTL_TEL_INPUT_UTILS_SCRIPT_URL,

        // Translations
        // TODO: Maybe add translations for each country?
        i18n: {
          selectedCountryAriaLabel: this.$t(
            "components.form.be_tel_input.selected_country_aria_label"
          ),

          countryListAriaLabel: this.$t(
            "components.form.be_tel_input.country_list_aria_label"
          ),

          searchPlaceholder: this.$t(
            "components.form.be_tel_input.search_placeholder"
          ),
        },
      });

      // Set the value if it exists
      if (this.modelValue) {
        this.ignoreNextInput = true;
        this.telInput.setNumber(String(this.modelValue) || "");

        // Special handling when component is not placed within a
        // BeFormGroup component. E.g. when used in a ERB form.
        // We have to wait for the number to be set before checking
        // if it's valid or not.
        setTimeout(() => {
          if (!this.isGroup && !this.telInput.isValidNumber()) {
            this.telInput.telInput.classList.add("is-invalid");
            this.localError = this.$t(
              "components.form.be_tel_input.invalid_phone_number"
            );
          }
        }, 500);
      }
    },

    handleInput() {
      if (this.ignoreNextInput) {
        this.ignoreNextInput = false;
        return;
      }

      this.$emit("input", this.telInput.getNumber());
      this.$emit("update:modelValue", this.telInput.getNumber());
    },

    handleChange() {
      this.$emit("change", this.telInput.getNumber());

      // Clear errors
      this.localError = null;
      this.telInput.telInput.classList.remove("is-invalid");
    },
  },
};
</script>
