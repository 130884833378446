<template>
  <be-dropdown-item v-be-modal.convert-report-to-template-modal>
    {{ $t("components.reports.save_as_template") }}
  </be-dropdown-item>

  <be-modal
    id="convert-report-to-template-modal"
    :title="$t('components.reports.save_as_template_modal.title')"
    @hidden="resetModal"
  >
    <be-form-group
      v-if="status === null || status === 422"
      label-for="template-name"
      :label="$t('components.reports.save_as_template_modal.template_title')"
      :error="getErrors(errors, 'title')"
    >
      <be-form-input
        id="template-name"
        v-model="templateName"
        :disabled="saving"
        required
        @input="clearErrors(errors, 'title')"
        @keydown.enter="createReportTemplate"
      />
    </be-form-group>

    <template v-else-if="status === 200">
      <be-alert variant="success">
        {{ $t("components.reports.save_as_template_modal.success") }}
      </be-alert>

      <p>
        {{
          $t(
            "components.reports.save_as_template_modal.continue_editing_or_go_to_template"
          )
        }}
      </p>
    </template>

    <template v-else>
      <be-alert variant="danger">
        {{ $t("components.reports.save_as_template_modal.error") }}
      </be-alert>
    </template>

    <template #footer="{ cancel }">
      <be-button v-if="status !== 200" variant="light" @click="cancel">
        {{ $t("buttons.titles.cancel") }}
      </be-button>

      <be-button
        v-if="status === null || status === 422"
        variant="primary"
        :loading="saving"
        @click="createReportTemplate"
      >
        {{ $t("buttons.titles.save") }}
      </be-button>

      <template v-if="status === 200">
        <be-button variant="light" @click="cancel">
          {{ $t("components.reports.save_as_template_modal.keep_editing") }}
        </be-button>

        <be-button
          ref="go-to-template"
          variant="primary"
          @click="navigateToTemplate"
        >
          {{ $t("components.reports.save_as_template_modal.go_to_template") }}
        </be-button>
      </template>
    </template>
  </be-modal>
</template>

<script>
export default {
  props: {
    buildUrl: {
      type: String,
      required: true,
    },

    reportId: {
      type: Number,
      required: true,
    },

    controllerAction: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      errors: {},
      saving: false,
      status: null,
      templateName: null,
      templateId: null,
    };
  },

  methods: {
    async createReportTemplate() {
      this.saving = true;

      try {
        const response = await axios.post(this.buildUrl, {
          report_id: this.reportId,
          template: { title: this.templateName },
        });
        this.templateId = response.data.id;

        // If the user is in report show mode, navigate to the template
        if (this.controllerAction === "show") {
          this.navigateToTemplate();
        } else {
          this.status = response.status;

          // Wait for button to be rendered before setting focus
          // This makes it possible to navigate using the Enter key
          setTimeout(() => {
            this.$refs["go-to-template"].$el.focus();
          }, 100);
        }
      } catch (error) {
        if (error.response?.status === 422) {
          this.errors = error.response.data;
        } else {
          this.handleError(error);
        }

        this.status = error.response.status;
      } finally {
        this.saving = false;
      }
    },

    navigateToTemplate() {
      window.location = this.url(`reports/templates/${this.templateId}/edit`);
    },

    resetModal() {
      this.errors = {};
      this.saving = false;
      this.status = null;
      this.templateName = "";
      this.templateId = null;
    },
  },
};
</script>
