<template>
  <div class="d-flex flex-column flex-md-row gap-1">
    <template
      v-if="!['edit', 'update'].some((action) => action === controllerAction)"
    >
      <be-button
        v-if="report.policy.update"
        variant="outline-primary"
        :href="url(`/reports/${report.id}/edit`)"
      >
        {{ $t("models.report.edit") }}
      </be-button>
    </template>

    <template v-if="versionsWithNumber.length > 1">
      <be-dropdown right>
        <template #button-content>
          {{ $t("components.reports.report_options.versions.title") }}
        </template>

        <be-dropdown-item
          v-for="version in versionsWithNumber"
          :key="`version-${version.id}`"
          :href="version.id === report.id ? '' : url(`/reports/${version.id}`)"
          :variant="version.id === report.id ? 'primary' : null"
        >
          {{ versionTitle(version) }}
        </be-dropdown-item>
      </be-dropdown>
    </template>

    <btn-toggle-fullscreen />

    <be-dropdown ellipsis>
      <convert-report-to-template-dropdown-item
        v-if="canPublish"
        :build-url="url('/reports/templates')"
        :report-id="report.id"
        :controller-action="controllerAction"
      />

      <be-dropdown-item v-if="copyOrVersionText" v-be-modal.copy-modal>
        {{ copyOrVersionText }}
      </be-dropdown-item>

      <be-dropdown-item
        v-if="canCreateDashboard"
        @click="createDashboard(report)"
      >
        {{ $t("components.reports.report_options.create_dashboard") }}
      </be-dropdown-item>

      <be-dropdown-item
        v-if="scheduledReportsPath(report)"
        :href="scheduledReportsPath(report)"
      >
        {{ $t("activerecord.models.report_schedule_rule.one") }}
      </be-dropdown-item>

      <export-tables-to-excel-dropdown-item
        v-if="flipperFlag('report_tables_export')"
        :report="report"
      />

      <be-dropdown-divider
        v-if="canPublish || copyOrVersionText || canCreateDashboard"
      />

      <be-dropdown-item
        v-if="$currentMembership.policy_level !== 'limited'"
        key="generate-new-pdf"
        v-be-tooltip="{
          title: renderNewPdfDisabledTooltip,
          disabled: !renderNewPdfDisabledTooltip,
        }"
        :disabled="renderNewPdfDisabled"
        @click="requestPdf"
      >
        <be-spinner v-if="pdf?.status === 'in_progress'">
          {{ $t("components.reports.pdf_tab.updating_pdf") }}
        </be-spinner>

        <template v-else>
          {{ $t("components.reports.pdf_tab.update_pdf") }}
        </template>
      </be-dropdown-item>

      <be-dropdown-item
        key="download-pdf"
        v-be-tooltip="{
          title: downloadPdfDisabledTooltip,
          disabled: !downloadPdfDisabledTooltip,
        }"
        :href="url(`/reports/${report.id}/pdf`)"
        :disabled="!pdf?.document"
      >
        {{ $t("components.reports.pdf_tab.pdf_download") }}
      </be-dropdown-item>

      <template v-if="showDestroyButton">
        <be-dropdown-divider />

        <be-dropdown-item
          v-be-tooltip="{
            title: $t(
              'components.reports.report_options.can_only_remove_latest_version'
            ),
            disabled: reportIsDestroyable,
          }"
          :disabled="!reportIsDestroyable"
          :href="url(`/reports/${report.id}`)"
          variant="danger"
          data-method="DELETE"
          :data-confirm="confirmRemoveReportText"
        >
          {{ $t("buttons.titles.remove") }}
        </be-dropdown-item>
      </template>
    </be-dropdown>

    <be-modal
      id="copy-modal"
      :key="`copy-modal-${report.id}-${randomUuid}`"
      :title="copyOrVersionText"
      :size="modalSize"
      ok-only
      :ok-title="$t('buttons.titles.cancel')"
      ok-variant="light"
      body-class="p-0"
      @hidden="randomUuid = generateUuid()"
    >
      <div class="row no-gutters">
        <div
          v-if="report.policy.copy"
          class="col-12 col-lg-auto"
          :class="{
            'col-lg-6 border-lg-right border-right-1':
              report.policy.create_new_version,
            'col-lg-auto': !report.policy.create_new_version,
          }"
        >
          <div
            class="d-flex flex-column h-100 p-3 border-bottom border-lg-bottom-0"
          >
            <h5 v-if="report.policy.create_new_version">
              {{ $t("components.reports.report_options.create_copy") }}
            </h5>

            <span
              v-dompurify-html="
                $t('components.reports.report_options.copy_description_html')
              "
              class="my-1"
            />

            <div class="d-md-flex justify-content-end mt-auto">
              <be-button
                variant="primary"
                :href="url(`/reports/${report.id}/copy`)"
                data-method="POST"
              >
                {{ $t("components.reports.report_options.create_copy") }}
              </be-button>
            </div>
          </div>
        </div>

        <div
          v-if="report.policy.create_new_version"
          class="col-12 d-flex flex-column"
          :class="{
            'col-lg-6': report.policy.copy,
            'col-lg-auto': !report.policy.copy,
          }"
        >
          <div class="d-flex flex-column h-100 p-3">
            <h5 v-if="report.policy.copy">
              {{ $t("components.reports.report_options.create_new_version") }}
            </h5>

            <span
              v-dompurify-html="
                $t('components.reports.report_options.version_description_html')
              "
              class="my-1"
            />

            <div class="d-md-flex justify-content-end">
              <be-button
                variant="primary"
                :href="url(`/reports/${report.id}/versions`)"
                data-method="POST"
              >
                {{ $t("components.reports.report_options.create_new_version") }}
              </be-button>
            </div>
          </div>
        </div>
      </div>
    </be-modal>

    <job-callback
      v-if="callback"
      :key="`job-callback-${callback?.id}`"
      class="mb-3"
      :callback="callback"
      @status="handleCallbackStatus"
    />
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";
import { createNamespacedHelpers } from "vuex";

import ConvertReportToTemplateDropdownItem from "./ConvertReportToTemplateDropdownItem.vue";
import ExportTablesToExcelDropdownItem from "./ExportTablesToExcelDropdownItem.vue";

const reportHelpers = createNamespacedHelpers("reports");

export default {
  components: {
    ConvertReportToTemplateDropdownItem,
    ExportTablesToExcelDropdownItem,
  },

  props: {
    report: {
      type: Object,
      required: true,
    },

    versions: {
      type: Array,
      required: false,
      default: () => [],
    },

    controllerAction: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      randomUuid: this.generateUuid(),
    };
  },

  computed: {
    ...mapGetters({
      hasActiveFeature: "company/hasActiveFeature",
      callbackByReference: "job_callbacks/getNewestJobCallbackByReference",
      getPdfForReport: "reports/getPdfForReport",
    }),

    pdf() {
      return this.getPdfForReport(this.report) || {};
    },

    callback() {
      return this.callbackByReference[`Report-${this.report.id}`];
    },

    // Always show something if we have permission to destroy this or the latest version
    showDestroyButton() {
      return (
        this.draftVersion?.policy?.destroy ||
        this.newestPublishedVersion?.policy?.destroy
      );
    },

    canPublish() {
      return this.report.policy.publish;
    },

    canCreateDashboard() {
      return this.hasActiveFeature("economy") && this.report.policy.copy;
    },

    confirmRemoveReportText() {
      if (this.versions?.length > 1) {
        return this.$t(
          "components.reports.report_options.confirm_remove_with_versions"
        );
      } else {
        return this.$t("nav.confirm_delete_w_title", {
          title: this.report.title,
        });
      }
    },

    copyOrVersionText() {
      if (this.report.policy.copy && this.report.policy.create_new_version) {
        return this.$t("components.reports.report_options.copy_or_version");
      } else if (this.report.policy.copy) {
        return this.$t("components.reports.report_options.create_copy");
      } else if (this.report.policy.create_new_version) {
        return this.$t("components.reports.report_options.create_new_version");
      } else {
        return null;
      }
    },

    modalSize() {
      if (this.report.policy.copy && this.report.policy.create_new_version) {
        return "lg";
      } else {
        return "md";
      }
    },

    renderNewPdfDisabled() {
      return this.pdf?.status === "in_progress";
    },

    renderNewPdfDisabledTooltip() {
      if (this.pdf?.status === "in_progress") {
        return this.$t("components.reports.pdf_tab.pdf_creation_in_progress");
      }

      return null;
    },

    downloadPdfDisabledTooltip() {
      if (this.pdf?.document?.id) {
        return null;
      } else {
        return this.$t("components.reports.pdf_tab.download_pdf_disabled");
      }
    },

    versionsWithNumber() {
      return this.versions.map((version, index) => {
        return {
          ...version,
          number: this.versions.length - index,
        };
      });
    },

    draftVersion() {
      if (this.versions[0]?.published_at) {
        return null;
      }

      return this.versions[0];
    },

    // In a sorted array the newest published report will always be first or second element.
    newestPublishedVersion() {
      if (this.versions[0]?.published_at) {
        return this.versions[0];
      }

      if (this.versions.length > 1 && this.versions[1]?.published_at) {
        return this.versions[1];
      }

      return null;
    },

    reportIsDestroyable() {
      if (this.draftVersion?.id === this.report.id) {
        return true;
      }

      if (
        this.newestPublishedVersion?.id === this.report.id &&
        !this.draftVersion
      ) {
        return true;
      }

      return false;
    },
  },

  methods: {
    ...reportHelpers.mapActions(["requestReportPdfGeneration"]),
    ...reportHelpers.mapMutations(["setPdfStatus"]),

    scheduledReportsPath(report) {
      if (report.report_schedule_rule?.report_template?.policy?.edit) {
        return `${report.report_schedule_rule.url}/edit`;
      }
    },

    requestPdf() {
      this.requestReportPdfGeneration({ report: this.report, flash: true });
    },

    handleCallbackStatus(status) {
      this.setPdfStatus({ report: this.report, status });

      if (status === "failed") {
        EventBus.emit("new-notification", {
          message: this.$t(
            "components.reports.pdf_tab.updating_pdf_notice.failed"
          ),

          status: "danger",
        });
      } else if (this.pdf?.document?.content_hash === "error") {
        EventBus.emit("new-notification", {
          message: this.$t(
            "components.reports.pdf_tab.updating_pdf_notice.completed_with_error"
          ),

          status: "notice",
        });
      }
    },

    versionTitle(version) {
      const parts = [
        this.$t("components.reports.report_options.versions.version"),
        version.number,
      ];

      if (version.id === this.draftVersion?.id) {
        parts.push(
          `(${this.$t("components.reports.report_options.versions.draft")})`
        );
      } else if (version.id === this.newestPublishedVersion?.id) {
        parts.push(
          `(${this.$t(
            "components.reports.report_options.versions.latest_published"
          )})`
        );
      }

      return parts.join(" ");
    },

    async createDashboard(report) {
      const isConfirmed = await this.promptConfirm({
        title: this.$t(
          "components.reports.report_options.create_dashboard_confirm_w_title",
          {
            title: report.title,
          }
        ),

        description: this.$t(
          "components.reports.report_options.create_dashboard_confirm_text"
        ),

        stayOpenAfterConfirm: true,
      });

      if (!isConfirmed) {
        return;
      }

      try {
        const { data } = await axios.post(
          this.url(`/reports/${report.id}/dashboard`)
        );

        const urlPrefix =
          report.report_type == "corporate_group" || report.corporate_group_data
            ? "corporate_groups"
            : "financials";

        window.location.assign(
          this.url(`/${urlPrefix}/dashboards/${data.id}/edit`)
        );
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
