<template>
  <be-modal
    :id="id"
    :title="$t('document_dropzone.select_uploaded')"
    modal-class="document-picker"
    scrollable
    size="lg"
    @show="modalShow"
  >
    <ol class="breadcrumb shadow-none p-0 mb-3">
      <li v-if="!!currentFolder.ancestors" class="breadcrumb-item">
        <a href="#" @click.prevent="loadFolder(null)">
          {{ $t("activerecord.models.document.other") }}
        </a>

        <be-spinner v-if="loading === 'root'" />
      </li>

      <li
        v-for="ancestor in currentFolder.ancestors"
        :key="ancestor.id"
        class="breadcrumb-item"
      >
        <a href="#" @click.prevent="loadFolder(ancestor)">
          {{ ancestor.title }}
        </a>

        <be-spinner v-if="loading === ancestor.id" />
      </li>

      <li class="breadcrumb-item active">
        {{ currentFolder.title }}
      </li>
    </ol>

    <table
      v-if="publicFolders.length || documents.length"
      class="table table-hover footable documents-table"
    >
      <thead>
        <tr>
          <th class="col-shrink" />

          <th class="col-shrink" />

          <th>{{ $t("activerecord.attributes.document.filename") }}</th>

          <th class="col-shrink">
            {{ $t("activerecord.attributes.document.uploaded_by") }}
          </th>

          <th class="col-shrink">
            {{ $t("activerecord.attributes.document.updated_at") }}
          </th>

          <th class="col-shrink">
            {{ $t("activerecord.attributes.document.filesize") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="folder in publicFolders" :key="`folder-${folder.id}`">
          <td class="col-shrink"></td>

          <td class="pl-1 text-muted text-center">
            <i class="fal fa-folder" />
          </td>

          <td class="pl-0">
            <a href="#" @click.prevent="loadFolder(folder)">
              {{ folder.title }}
            </a>

            <be-spinner v-if="loading === folder.id" />
          </td>

          <td class="col-shrink" />

          <td class="col-shrink">
            <div>
              {{ $d(new Date(folder.created_at), { format: "mini" }) }}
            </div>
          </td>

          <td class="col-shrink" />
        </tr>

        <tr
          v-for="doc in documents"
          :key="`document-${doc.id}`"
          @click="selectItem(doc)"
        >
          <td class="col-shrink">
            <!-- TODO: Disable this checkbox if "isSingleFile" is true and there's another document selected -->
            <be-form-checkbox
              :checked="selected(doc)"
              @change="selectItem(doc)"
            />
          </td>
          <!-- eslint-disable vue/no-v-html -->
          <td
            class="col-shrink pl-0 text-muted text-center"
            v-html="getIconForFilename(doc.filename)"
          />
          <!-- eslint-enable vue/no-v-html -->
          <td class="pl-0">
            {{ doc.filename }}
          </td>

          <td class="col-shrink">
            <user-avatar
              v-if="getUser(doc.uploaded_by_id)"
              :user="getUser(doc.uploaded_by_id)"
            />
          </td>

          <td class="col-shrink">
            <div>
              {{ $d(new Date(doc.created_at), { format: "mini" }) }}
            </div>
          </td>

          <td class="col-shrink">
            {{ doc.file_size }}
          </td>
        </tr>
      </tbody>
    </table>

    <be-skeleton-table
      v-else-if="!!loading"
      :rows="5"
      :columns="5"
      :table-props="{ hover: true }"
    />

    <be-alert v-else variant="info">
      {{ $t("documents.no_documents") }}
    </be-alert>

    <template #footer>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <p v-if="selectedDocuments.length > 0" class="text-muted m-0">
          {{
            $tc(
              "components.file_uploader.selected_documents_w_count",
              selectedDocuments.length,
              { count: selectedDocuments.length }
            )
          }}
        </p>

        <div class="ml-auto">
          <be-button variant="light" @click="modalCancel">
            {{ $t("buttons.titles.cancel") }}
          </be-button>

          <be-button
            variant="primary"
            :disabled="selectedDocuments.length == 0 || loading"
            :loading="loading"
            @click="modalOk"
          >
            {{ $t("buttons.titles.select") }}
          </be-button>
        </div>
      </div>
    </template>
  </be-modal>
</template>

<script>
import { documentArchiveNavigation } from "@/components/documents/documentArchiveNavigation";
import { getIconForFilename } from "@/vendor/font-awesome-filetypes";
import uniqueId from "lodash/uniqueId";

export default {
  mixins: [documentArchiveNavigation],

  props: {
    isSingleFile: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,

      default: () => {
        `document-picker-id-${uniqueId()}`;
      },
    },
  },

  emits: ["archive-document-selected"],

  computed: {
    selectedDocuments() {
      return this.selectedItems.documents;
    },

    publicFolders() {
      return this.folders.filter((folder) => !folder.is_private);
    },
  },

  methods: {
    selected(item) {
      return this.selectedDocuments.includes(item);
    },

    selectItem(item) {
      if (this.selected(item)) {
        let idx = this.selectedDocuments.findIndex(
          (selectedItem) => selectedItem.id == item.id
        );
        this.selectedDocuments.splice(idx, 1);
      } else {
        if (this.isSingleFile) {
          while (this.selectedDocuments.length > 0) {
            this.selectedDocuments.pop();
          }
        }
        this.selectedDocuments.push(item);
      }
    },

    modalShow() {
      this.loadFolder();
    },

    modalOk() {
      this.$emit("archive-document-selected", this.selectedDocuments);
      this.selectedItems.documents = [];
      this.$beModal.hide(this.id);
    },

    modalCancel() {
      this.selectedItems.documents = [];
      this.$beModal.hide(this.id);
    },

    getIconForFilename(filename) {
      return filename ? getIconForFilename(filename) : null;
    },
  },
};
</script>
