<template>
  <be-modal
    id="client-manager-report-publish-modal"
    :title="$t('components.reports.client_manager_publications.settings')"
    :ok-title="clientManagerPublicationsButtonTitle"
    :ok-disabled="disableOk"
    size="md"
    @show="show"
    @hidden="reset"
    @ok="handlePublish"
  >
    <div class="d-flex flex-column">
      <be-form-group
        :label="
          $t('components.reports.client_manager_publications.scopes.label')
        "
      >
        <be-form-radio-group
          id="publication-scope"
          v-model="localReport.publication_scope"
          name="publication_scope"
          :options="publicationScopeOptions"
          stacked
        />
      </be-form-group>

      <template v-if="localReport.publication_scope == 'selected'">
        <be-skeleton v-if="loading" />

        <template v-else>
          <be-form-select
            v-model="selectedPublications"
            :options="publications"
            multiple
          />
          <be-alert
            v-if="selectedPublications.length == 0"
            variant="warning"
            class="mt-3"
          >
            {{ noPublicationsWarning }}
          </be-alert>
        </template>
      </template>
    </div>
  </be-modal>
</template>
<script>
import axios from "axios";

export default {
  props: {
    report: {
      type: Object,
      required: true,
    },
  },

  emits: ["report-published"],

  data() {
    return {
      localReport: this.cloneDeep(this.report),
      modalId: `publish-modal-${this.generateUuid()}`,
      loading: false,
      publications: [],
      selectedPublications: [],

      fields: [
        {
          key: "title",
          label: this.$t("activerecord.attributes.company.title"),
          sortable: true,
        },
      ],
    };
  },

  computed: {
    disableOk() {
      const noPublicationsSelected =
        this.localReport.publication_scope != "all" &&
        this.selectedPublications.length == 0;

      return !this.localReport.published_at && noPublicationsSelected;
    },

    publicationScopeOptions() {
      const descriptionKey =
        this.localReport.type == "Dashboard"
          ? "components.reports.client_manager_publications.scopes.descriptions.dashboards.all"
          : "components.reports.client_manager_publications.scopes.descriptions.report_templates.all";

      return [
        {
          value: "all",

          text: this.$t(
            "components.reports.client_manager_publications.scopes.values.all"
          ),

          description: this.$t(descriptionKey),
        },

        {
          value: "selected",

          text: this.$t(
            "components.reports.client_manager_publications.scopes.values.selected"
          ),
        },
      ];
    },

    noPublicationsWarning() {
      const warningKey =
        this.localReport.type == "Dashboard"
          ? "components.reports.client_manager_publications.no_publications.dashboards"
          : "components.reports.client_manager_publications.no_publications.report_templates";
      return this.$t(warningKey);
    },

    clientManagerPublicationsButtonTitle() {
      if (this.localReport.published_at) {
        return this.$t(
          "components.reports.client_manager_publications.settings"
        );
      }
      return this.$t("buttons.titles.publish");
    },
  },

  methods: {
    async show() {
      this.loading = true;
      this.publications = [];
      this.selectedPublications = [];
      try {
        const response = await axios.get(this.report.paths.publications);
        this.localReport = response.data;
        this.localReport.publications.forEach((publication) => {
          this.publications.push({
            label: publication.admin_company.company.title,
            value: publication.admin_company.id,
          });

          if (publication.id) {
            this.selectedPublications.push(publication.admin_company.id);
          }
        });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    reset() {
      this.loading = false;
    },

    async handlePublish() {
      try {
        const response = await axios.post(this.localReport.paths.publications, {
          admin_company_ids: this.selectedPublications,
          publication_scope: this.localReport.publication_scope,
        });
        this.$beModal.hide(this.modalId);
        this.$emit("report-published", response.data);
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
