<template>
  <be-input-group>
    <be-form-datepicker
      v-bind="datePickerProps"
      v-model="date"
      :clearable="false"
    />

    <be-input-group-append
      class="flex-wrap mt-2 gap-0"
      :class="{ 'mt-lg-0': displayInline }"
    >
      <be-button
        v-if="showNextMeeting && nextMeetingDate"
        v-bind="buttonProps"
        @click="setNextMeetingDate"
      >
        {{ $t("components.form.datepicker_future_buttons.next_meeting") }}
      </be-button>

      <be-button
        v-if="showDays"
        v-bind="buttonProps"
        icon="fa-plus"
        @click="appendDays(1)"
      >
        {{ $t("components.form.datepicker_future_buttons.one_day") }}
      </be-button>

      <be-button
        v-if="showDays"
        v-bind="buttonProps"
        icon="fa-plus"
        @click="appendDays(2)"
      >
        {{ $t("components.form.datepicker_future_buttons.two_days") }}
      </be-button>

      <be-button
        v-if="showWeek"
        v-bind="buttonProps"
        icon="fa-plus"
        @click="appendWeek"
      >
        {{ $t("components.form.datepicker_future_buttons.one_week") }}
      </be-button>

      <be-button
        v-if="showMonth"
        v-bind="buttonProps"
        icon="fa-plus"
        @click="appendMonth"
      >
        {{ $t("components.form.datepicker_future_buttons.one_month") }}
      </be-button>

      <be-button
        v-if="showClear"
        v-bind="buttonProps"
        icon="fa-times"
        @click="clearDate"
      />
    </be-input-group-append>
  </be-input-group>
</template>

<script>
import { add } from "date-fns";

export default {
  props: {
    datePickerProps: { type: Object, default: () => {} },

    buttonProps: {
      type: Object,

      default: () => ({
        variant: "outline-secondary",
        class: "p-1",
        squared: true,
      }),
    },

    showNextMeeting: { type: Boolean, default: false },
    showDays: { type: Boolean, default: true },
    showWeek: { type: Boolean, default: true },
    showMonth: { type: Boolean, default: true },
    showClear: { type: Boolean, default: true },
    displayInline: { type: Boolean, default: false },
    modelValue: { type: String, default: "" },

    // callback that should return next meeting date
    getMeetingDate: { type: Function, required: false, default: () => {} },
  },

  emits: ["input", "update:modelValue"],

  data() {
    return {
      date: this.modelValue || "",
    };
  },

  computed: {
    nextMeetingDate() {
      return this.getMeetingDate();
    },
  },

  watch: {
    date(newValue) {
      if (newValue === "") {
        this.$emit("input", "");
        this.$emit("update:modelValue", "");
      } else {
        this.$emit("input", new Date(newValue).toISOString());
        this.$emit("update:modelValue", new Date(newValue).toISOString());
      }
    },
  },

  methods: {
    setNextMeetingDate() {
      this.date = this.getMeetingDate();
    },

    appendDays(count) {
      this.appendTime({ days: count });
    },

    appendWeek() {
      this.appendTime({ weeks: 1 });
    },

    appendMonth() {
      this.appendTime({ months: 1 });
    },

    appendTime(params) {
      const currentDate = this.date ? new Date(this.date) : new Date();
      const newDate = add(currentDate, params);
      this.date = newDate;
    },

    clearDate() {
      this.date = "";
    },
  },
};
</script>
