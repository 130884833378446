<template>
  <be-badge
    v-if="unreadReleaseNotes.length > 0"
    v-cloak
    variant="notification"
    pill
  >
    {{ unreadReleaseNotes.length }}
  </be-badge>

  <slot v-else />
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const sessionStore = createNamespacedHelpers("session");

export default {
  computed: {
    ...sessionStore.mapGetters({
      releaseNotes: "getReleaseNotes",
    }),

    unreadReleaseNotes() {
      return (this.releaseNotes || []).filter((releaseNote) => {
        return (
          !releaseNote.read &&
          releaseNote.categories.some((category) => {
            return this.$currentUser.release_note_categories.includes(category);
          })
        );
      });
    },
  },
};
</script>
