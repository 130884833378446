const isHex = (color) => {
  return typeof color === "string" && color.startsWith("#");
};

const hexToRgb = (hex) => {
  if (!isHex(hex)) {
    throw new Error("Input is not a hex color");
  }

  const hexValue = hex.replace("#", "");
  const r = parseInt(hexValue.substring(0, 2), 16);
  const g = parseInt(hexValue.substring(2, 4), 16);
  const b = parseInt(hexValue.substring(4, 6), 16);

  return [r, g, b];
};

const rgbToHex = (rgb) => {
  // If rgb is not an array, convert it to one
  if (!Array.isArray(rgb)) {
    rgb = rgb.replace(/[^\d,]/g, "").split(",");
  }

  const hex = rgb.map((value) => {
    const hexValue = value.toString(16);
    return hexValue.length === 1 ? `0${hexValue}` : hexValue;
  });

  return `#${hex.join("")}`;
};

const saturateColor = (color, amount) => {
  if (isHex(color)) {
    color = hexToRgb(color);
  }

  const [r, g, b] = color;
  const newR = Math.min(Math.round(r * amount), 255);
  const newG = Math.min(Math.round(g * amount), 255);
  const newB = Math.min(Math.round(b * amount), 255);

  return [newR, newG, newB];
};

const lightenColor = (color, amount) => {
  const returnAsHex = isHex(color);

  if (isHex(color)) {
    color = hexToRgb(color);
  }

  const [r, g, b] = color;
  const newR = Math.min(r + amount, 255);
  const newG = Math.min(g + amount, 255);
  const newB = Math.min(b + amount, 255);

  return returnAsHex ? rgbToHex([newR, newG, newB]) : [newR, newG, newB];
};

const darkenColor = (color, amount) => {
  const returnAsHex = isHex(color);

  if (isHex(color)) {
    color = hexToRgb(color);
  }

  const [r, g, b] = hexToRgb(color);
  const newR = Math.max(r - amount, 0);
  const newG = Math.max(g - amount, 0);
  const newB = Math.max(b - amount, 0);

  return returnAsHex ? rgbToHex([newR, newG, newB]) : [newR, newG, newB];
};

const featureBackgroundColor = (featureName) => {
  // Extracted using ColorThief, but cannot reproduce it as a shell script
  // https://lokeshdhakar.com/projects/color-thief/ use as inspiration for new colors.
  // The initial colors were added based on `colorthief-standalone` that was running in the browser.
  // See https://github.com/Boardeaser/uppstyrt/blob/7200d309e1f4e7b9e81d4196a1d24e98141e666b/app/javascript/src/components/shop/ShopFeature.vue#L527-L559
  // and https://github.com/Boardeaser/uppstyrt/blob/7200d309e1f4e7b9e81d4196a1d24e98141e666b/app/javascript/src/utils/color.js
  const FEATURE_BACKGROUNDS = {
    "e-signature": "#dcf3ff",
    economy: "#d6fbf3",
    reports: "#ffd8dc",
    shareholder_register: "#e8dedc",
    video_meeting: "#ecfffd",
    projects: "#e4e8ec",
    "economy-business-unit": "#ffecf1",
    annual_report: "#ffe8de",
    "economy-corporate-group-consolidation": "#fffbd3",
    "economy-kpi": "#eae4ff",
    evaluation: "#cbe6ea",
    contracts: "#fff9c9",
    ios: "#f7ffff",
    board_portal: "#ffd8dc",
    bjorn_lunden: "#c9c9c9",
    dropbox: "#c9e2ff",
    quickbooks: "#d3f2cf",
    fortnox: "#c9d8d1",
    google_drive: "#d1f2e2",
    pe_accounting: "#c9f5ff",
    vitec: "#ffc9d6",
    visma_e_accounting: "#cfe6f8",
    visma_net: "#ffc9da",
  };

  if (FEATURE_BACKGROUNDS[featureName] === undefined) {
    window.Sentry.captureMessage(
      `No background color found for feature: ${featureName}`
    );
    return "#f5f5f5";
  }

  return FEATURE_BACKGROUNDS[featureName];
};

export {
  isHex,
  hexToRgb,
  rgbToHex,
  saturateColor,
  lightenColor,
  darkenColor,
  featureBackgroundColor,
};
