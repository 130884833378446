import debounce from "lodash/debounce";

const AUTOSAVE_INTERVAL = 2000;
const AUTOSAVE_INITIAL_GRACE_PERIOD = 500;
const AUTOSAVE_GRACE_PERIOD = 1600;

export default {
  data() {
    const tryAutoSave = debounce(async function (data) {
      if (!this.autoSave) {
        return;
      }

      this.setAutosave(false);

      const success = await this.saveRequest(data);

      this.cancelAnyAutosave();
      this.reenableAutosave();
      if (!success) {
        if (this.notifyAutosaveFailure) {
          this.notifyAutosaveFailure();
        }
      }
    }, AUTOSAVE_INTERVAL);

    return {
      tryAutoSave,
      autoSave: false,
      cancelAutoSave: tryAutoSave.cancel,
      flushAutoSave: tryAutoSave.flush,
      updateDisabled: false,
      failedToSave: false,
    };
  },

  async mounted() {
    // Grace period to populate data from store. Do not trigger autosave watcher
    this.reenableAutosave(AUTOSAVE_INITIAL_GRACE_PERIOD);
  },

  methods: {
    setAutosave(val) {
      this.autoSave = val;
    },

    cancelAnyAutosave() {
      this.cancelAutoSave();
    },

    reenableAutosave(delay = AUTOSAVE_GRACE_PERIOD) {
      setTimeout(() => this.setAutosave(true), delay);
    },

    notifyAutosaveFailure() {
      // Do nothing, override in component
    },

    async saveRequest() {
      // Do nothing, override in component
      return false;
    },

    setDirty() {
      // Do nothing, override in component
    },
  },
};
