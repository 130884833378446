<template>
  <div>
    <be-tabs
      v-if="hasMultipleTabs"
      :model-value="activeTab"
      tab-id="attachments"
      :options="tabData"
      @update:model-value="activatedTab = $event"
    />

    <div v-if="activeTab == 'documents'" class="card">
      <div class="card-body">
        <attachment-table :documents="attachments" />
      </div>
    </div>

    <financials-visual
      v-for="attachment in financialAttachments"
      :key="`financial-${attachment.id}`"
      v-show="activeTab === attachment.id.toString()"
      :configuration="attachment.configuration"
      :force-origin="attachment.origin"
      :force-end-date-string="reportEndDateString(report)"
      :forced-financials-uuid="reportFinancialsUuid(report)"
      card
      @financials-initialized="financialInitialized(attachment)"
    />
  </div>
</template>

<script>
import ReportMixin from "@/mixins/reports";
import { mapActions } from "vuex";

import AttachmentTable from "@/components/documents/AttachmentTable.vue";

const AUTO_READ_DELAY = 1000;

export default {
  components: {
    AttachmentTable,
  },

  mixins: [ReportMixin],

  props: {
    report: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      activatedTab: null,
      autoReadDebounce: null,
      initializedFinancialAttachments: [],
      financialsReady: false,
    };
  },

  mounted() {
    this.waitForFinancials(() => {
      this.financialsReady = true;
    });
  },

  computed: {
    activeTab() {
      if (this.activatedTab) {
        return this.activatedTab;
      } else {
        return Object.keys(this.tabData)[0];
      }
    },

    attachments() {
      return this.report.attachments || [];
    },

    financialAttachments() {
      return this.report.financial_attachments || [];
    },

    activeFinancialAttachment() {
      return this.financialAttachments.find(
        (attachment) => attachment.id.toString() === this.activeTab
      );
    },

    hasMultipleTabs() {
      return Object.keys(this.tabData).length > 1;
    },

    tabData() {
      let tabs = {};

      if (this.attachments.length > 0) {
        tabs.documents = {
          value: "documents",
          label: this.$t("models.report.attachments"),
        };
      }

      this.financialAttachments.forEach((attachment, index) => {
        tabs[attachment.id] = {
          value: `${attachment.id}`,
          label: this.attachmentName(attachment, index),
        };
      });

      return tabs;
    },
  },

  watch: {
    activeFinancialAttachment: {
      handler(attachment) {
        if (
          attachment &&
          this.initializedFinancialAttachments.includes(attachment.id)
        ) {
          this.debounceReading(attachment);
        }
      },

      deep: true,
    },
  },

  beforeUnmount() {
    clearTimeout(this.autoReadDebounce);
  },

  methods: {
    ...mapActions({
      createReading: "readings/createReading",
    }),

    attachmentName(attachment, index) {
      return (
        attachment.name ||
        (this.financialsReady
          ? this.getConfigurationName(attachment.configuration)
          : "") ||
        `${this.$t("activerecord.models.report_financial_attachment.one")} ${index + 1}`
      );
    },

    financialInitialized(attachment) {
      if (!this.initializedFinancialAttachments.includes(attachment.id)) {
        this.initializedFinancialAttachments.push(attachment.id);
      }

      if (this.activeTab === attachment.id.toString()) {
        this.debounceReading(attachment);
      }
    },

    debounceReading(attachment) {
      if (this.autoReadDebounce) {
        clearTimeout(this.autoReadDebounce);
      }

      this.autoReadDebounce = setTimeout(() => {
        this.createReading(attachment);
      }, AUTO_READ_DELAY);
    },
  },
};
</script>
