<template>
  <div>
    <be-skeleton-table
      v-if="loading"
      :rows="3"
      :columns="4"
      :table-props="{ striped: true }"
    />

    <be-table
      v-else
      :fields="fields"
      :items="itemsWithUsers"
      :per-page="perPage"
    >
      <template #icon="{ item }">
        <i v-be-tooltip="`${item.description}`" :class="`fal ${item.icon}`" />
      </template>

      <template
        v-for="user in sortedUsersByName"
        #[`head(user-${user.id})`]
        :key="`user-${user.id}`"
      >
        <user-avatar :user="user.id" />
      </template>

      <template #title="{ item }">
        <template v-if="item.type === 'Document'">
          <document-link
            :document-id="item.id"
            :filename="item.filename"
            :icon="item.fontawesome_icon"
            :title="item.title"
            :show-icon="false"
          />
        </template>

        <template v-else>
          {{ item.title || item.name }}
        </template>
      </template>

      <template #actions="{ item }">
        <be-button
          v-if="item.type === 'Document'"
          v-be-tooltip="$t('buttons.titles.share')"
          variant="outline-secondary"
          size="sm"
          icon="fa-share"
          inline
          @click="handleShare(item.object)"
        />
      </template>

      <template
        v-for="user in sortedUsersByName"
        #[`user-${user.id}`]="{ item }"
      >
        <be-button
          v-if="user.id == $currentUser.id && !userReading(user, item)"
          :key="`user-${user.id}`"
          v-be-tooltip="$t('buttons.titles.mark_as_read')"
          variant="outline-success"
          size="sm"
          icon="fa-check"
          inline
          @click="createReading(item)"
        />

        <be-status
          v-else-if="userReadReferenceAt(user, item)"
          :key="`user-status-${user.id}`"
          size="xl"
          variant="success"
          class="justify-content-center"
          :tooltip="`${$t(
            'activerecord.attributes.reading.read'
          )} ${userReadReferenceAt(user, item)}`"
        />
      </template>
    </be-table>

    <inform-user-modal
      :document="shareDocument"
      :form-url="url('documents/send_reminder')"
      show-automatically
      exclude-current-user
      @reset="() => (shareDocument = {})"
    />
  </div>
</template>

<script>
import InformUserModal from "@/components/shared/InformUserModal.vue";
import ReportMixin from "@/mixins/reports";
import { compareText } from "@/utils/text-utils";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    InformUserModal,
  },

  mixins: [ReportMixin],

  props: {
    report: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      shareDocument: {},
      loading: true,
      financialsReady: false,
    };
  },

  computed: {
    ...mapGetters({
      companyUsers: "company/users",
      readingsForReference: "readings/readingsForReference",
      invitationsForReference: "invitations/invitationsForReference",
    }),

    reportReadings() {
      return this.readingsForReference("Report", this.report.id);
    },

    attachmentReadings() {
      const readings = {};

      this.attachments.forEach((document) => {
        readings[document.id] = this.readingsForReference(
          "Document",
          document.id
        );
      });

      return readings;
    },

    financialAttachmentReadings() {
      const readings = {};

      this.financialAttachments.forEach((attachment) => {
        readings[attachment.id] = this.readingsForReference(
          "ReportFinancialAttachment",
          attachment.id
        );
      });

      return readings;
    },

    attachments() {
      return this.report.attachments || [];
    },

    financialAttachments() {
      return this.report.financial_attachments || [];
    },

    invitations() {
      return this.invitationsForReference("Report", this.report.id);
    },

    itemsWithUsers() {
      const report = {
        ...this.report,
        type: "Report",
        description: this.$t("activerecord.models.report.one"),
        icon: "fa-layer-group",
        readings: this.reportReadings || [],
        users: this.sortedUsersByName,
      };

      return [
        report,
        ...this.attachments.map((doc) => {
          return {
            ...doc,
            type: "Document",
            description: this.$t("models.report.attachment"),
            icon: "fa-paperclip",
            readings: this.attachmentReadings[doc.id] || [],
            users: this.sortedUsersByName,
          };
        }),
        ...this.financialAttachments.map((attachment) => {
          return {
            ...attachment,
            type: "ReportFinancialAttachment",
            icon: "fa-table",
            readings: this.financialAttachmentReadings[attachment.id] || [],
            users: this.sortedUsersByName,
            name: this.attachmentName(attachment),

            description: this.$t(
              "activerecord.models.report_financial_attachment.one"
            ),
          };
        }),
      ];
    },

    fields() {
      return [
        {
          key: "icon",
          label: "",
          class: "col-shrink text-center",
        },
        {
          key: "title",
          label: this.translateAttribute("document", "title"),
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          class: "col-shrink",
        },
        ...this.sortedUsersByName.map((user) => ({
          key: `user-${user.id}`,
          label: user.name,
          class: "col-shrink text-center",
        })),
      ];
    },

    activeUserIds() {
      const userIds = new Set();
      this.invitations.forEach((invitation) => {
        if (invitation.checked) {
          userIds.add(invitation.user_id);
        }
      });

      this.reportReadings.forEach((reading) => {
        userIds.add(reading.user_id);
      });

      Object.values(this.attachmentReadings).forEach((readings) => {
        readings.forEach((reading) => {
          userIds.add(reading.user_id);
        });
      });

      Object.values(this.financialAttachmentReadings).forEach((readings) => {
        readings.forEach((reading) => {
          userIds.add(reading.user_id);
        });
      });

      return userIds;
    },

    sortedUsersByName() {
      return this.companyUsers
        .filter((user) => this.activeUserIds.has(user.id))
        .sort((a, b) => compareText(a, b, "name"));
    },

    perPage() {
      return this.attachments.length + this.financialAttachments.length + 1;
    },
  },

  watch: {
    async report(report) {
      if (report?.id) {
        this.loading = true;
        await this.fetchReadings(this.report);
        await this.fetchInvitations(this.report);
        this.loading = false;
      }
    },
  },

  async mounted() {
    await this.fetchReadings(this.report);
    await this.fetchInvitations(this.report);
    this.loading = false;

    this.waitForFinancials(() => {
      this.financialsReady = true;
    });
  },

  methods: {
    ...mapActions({
      fetchInvitations: "invitations/fetchInvitations",
      fetchReadings: "readings/fetchReadings",
      createReading: "readings/createReading",
    }),

    userReading(user, reference) {
      return reference.readings.find(
        (reading) => user.id && reading.user_id === user.id
      );
    },

    userReadReferenceAt(user, reference) {
      const readAt = this.userReading(user, reference)?.updated_at;

      if (readAt) {
        return this.$d(new Date(readAt), "dateTime");
      } else {
        return null;
      }
    },

    createReadingForReference(reference) {
      if (reference.type === "Report") {
        this.createReading(this.report);
      } else {
        this.createReading(reference);
      }
    },

    handleShare(doc) {
      this.shareDocument = doc;
    },

    attachmentName(attachment) {
      return (
        attachment.name ||
        (this.financialsReady
          ? this.getConfigurationName(attachment.configuration)
          : "") ||
        this.$t("activerecord.models.report_financial_attachment.one")
      );
    },
  },
};
</script>
