<template>
  <div class="card">
    <div class="card-header d-flex justify-content-end">
      <be-button v-be-modal.upload-file-kpi variant="outline-secondary">
        {{ $t("components.financials.data_variables.upload_file") }}
      </be-button>

      <financials-export-button
        variant="kpiExport"
        :title="$t('components.financials.data_variables.download_template')"
        class="ml-1"
      />

      <be-button
        :href="`${urlPrefix}/data_variables/new`"
        variant="outline-primary"
      >
        {{ $t("components.financials.data_variables.new") }}
      </be-button>
    </div>

    <div class="card-body">
      <be-table
        v-if="configurations.length > 0"
        :items="configurations"
        :fields="fields"
      >
        <template #name="{ item }">
          <be-link :href="`${urlPrefix}/data_variables/${item.uuid}/edit`">
            {{ item.name }}
          </be-link>

          <div v-if="item.descriptiveName" class="small text-muted">
            {{ item.descriptiveName }}
          </div>
        </template>

        <template #description="{ item }">
          {{ item.description }}
        </template>

        <template #last-data-modified="{ item }">
          <template v-if="item.lastDataModified">
            {{ $d(new Date(item.lastDataModified), "date") }}
          </template>

          <template v-else>-</template>
        </template>

        <template #visibility="{ item }">
          <be-badge
            :variant="item.visibility == 'DEFAULT' ? 'success' : 'secondary'"
          >
            {{
              $t(
                `components.financials.shared.visibility.${item.visibility.toLowerCase()}`
              )
            }}
          </be-badge>
        </template>
      </be-table>

      <be-alert v-else variant="info">
        {{ $t("components.financials.data_variables.empty") }}
      </be-alert>
    </div>

    <be-modal
      id="upload-file-kpi"
      :title="$t('views.companies.economy.modals.upload_file.title.kpi')"
    >
      <p>
        {{ $t("views.companies.economy.modals.upload_file.description.kpi") }}
      </p>

      <financial-file-uploader variant="kpi" />
    </be-modal>
  </div>
</template>

<script>
import RequestQueryMixin from "@/mixins/RequestQuery";
import FinancialFileUploader from "@/components/file_uploader/FinancialFileUploader.vue";
import FinancialsExportButton from "@/components/financials/editors/FinancialsExportButton.vue";

export default {
  components: {
    FinancialFileUploader,
    FinancialsExportButton,
  },

  mixins: [RequestQueryMixin],

  props: {
    urlPrefix: {
      type: String,
      required: true,
    },

    configurations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fields: [
        {
          key: "name",
          label: this.$t("components.financials.shared.variable"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "description",
          label: this.$t("components.financials.shared.description"),
          sortable: true,
        },
        {
          key: "lastDataModified",
          label: this.$t("components.financials.shared.last_updated"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "visibility",
          label: this.$t("components.financials.shared.visibility.title"),
          class: "col-shrink",
          sortable: true,
        },
      ],
    };
  },

  created() {
    // Do not fetch integrations for AdminPanel
    if (this.$currentCompany?.id) {
      this.$store.dispatch("company/fetchEnabledIntegrations");
    }
  },
};
</script>
