<template>
  <div id="origin-label-editor"></div>
</template>

<script>
export default {
  data() {
    return {
      initialized: false,
      selector: "#origin-label-editor",
    };
  },

  mounted() {
    this.waitForFinancials(this.initializeComponent);
  },

  methods: {
    initializeComponent() {
      if (this.initialized) {
        return;
      }

      this.initialized = true;

      // Initialize Component
      window
        .wzGetController()
        .addOriginLabelEditor(this.selector, this.$activeFinancialsUuid);
    },
  },
};
</script>
