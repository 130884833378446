import axios from "axios";
import { handleError } from "@/utils/error-handling";

// Constant for the 'Content-Type' header to avoid typos
const CONTENT_TYPE = "Content-Type";

// Creates a FormData object with optional file details and additional parameters
function createFormData(file, params = {}, includeFileDetails = false) {
  const formData = new FormData();

  // Include file details only when required (e.g., for getting signed URLs))
  if (includeFileDetails) {
    formData.append("filePath", file.name); // File path for the server to recognize
    formData.append("contentType", file.type); // File MIME type
  }

  // Append additional parameters to the FormData object
  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
}

// Configure Axios request with optional headers and withCredentials flag
function configureAxios(headers = {}, withCredentials = false) {
  return { headers, withCredentials };
}

// Makes a POST request using Axios and handles errors
async function axiosPost(url, data, config) {
  try {
    const { data: responseData } = await axios.post(url, data, config);
    return responseData; // Return the response data from the request
  } catch (error) {
    handleError(error); // Handle the error and log it to the console and Sentry
    throw error; // Re-throw the error to the caller
  }
}

export default {
  // Gets a signed URL for the file upload by sending file details to the server
  async getSignedUrl(file, options = {}) {
    const {
      signingUrl, // Endpoint for getting the signed URL
      headers = {},
      withCredentials = false,
      params = {},
    } = options;

    const formData = createFormData(file, params, true); // Include file details in FormData for this request
    const axiosConfig = configureAxios(headers, withCredentials);

    // Request the signed URL from the server
    return await axiosPost(signingUrl, formData, axiosConfig);
  },

  // Uploads a file to a specified URL using the signed URL obtained from getSignedUrl
  async uploadFile(file, url, config = {}) {
    // Enhance the provided config with additional necessary headers and params
    const enhancedConfig = {
      headers: { [CONTENT_TYPE]: file.type },
      params: { Secure: true }, // Additional security parameter
      signingUrl: url, // Endpoint for the actual file upload
      ...config,
    };

    try {
      // Get the signed URL and additional data for uploading the file
      const response = await this.getSignedUrl(file, enhancedConfig);

      // Prepare a FormData object for file upload, excluding file details
      const formData = createFormData(file, response.signature || {});
      formData.append("file", file); // Append the actual file to be uploaded

      // Perform the file upload using the signed URL
      const { data } = await axios.post(response.postEndpoint, formData, {
        headers: { [CONTENT_TYPE]: file.type },
      });

      // Parse the XML response to extract the file key (URL) from the S3 bucket
      const parsedResponse = new DOMParser().parseFromString(data, "text/xml");
      const key = parsedResponse.querySelector("Key").textContent;

      // Return information about the uploaded file
      return {
        uuid: file.uuid,
        key,
        upload_state: "added", // Custom state indicator
        relative_path: file.fullPath || file.webkitRelativePath, // Useful for files selected from a directory
        fullPath: file.fullPath || file.webkitRelativePath, // Full path of the uploaded file

        metadata: {
          filename: file.name,
          mime_type: file.type,
          size: file.size,
        },
      };
    } catch (error) {
      handleError(error); // Handle the error and log it to the console and Sentry
      throw error; // Re-throw the error to the caller
    }
  },
};
