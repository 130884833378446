function reportTabsDataShow(report) {
  const data = {
    report: {
      value: "report",
      label: this.$t("activerecord.models.report.one"),
    },
  };

  const attachmentCount =
    (report.attachments || []).length +
    (report.financial_attachments || []).length;

  if (attachmentCount > 0) {
    data.attachments = {
      value: "attachments",
      label: this.$t("models.report.attachments"),
      count: attachmentCount,
    };
  }

  data.participants = {
    value: "participants",
    label: this.$t("views.companies.reports.nav.participants"),
  };

  data.pdf = {
    value: "pdf",
    label: this.$t("views.companies.reports.nav.pdf"),
  };

  if (report.published_at) {
    data.readings = {
      value: "readings",
      label: this.$t("activerecord.models.reading.other"),
    };
  }

  return data;
}

export { reportTabsDataShow };
