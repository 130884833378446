<template>
  <be-modal
    :id="modalId"
    :title="
      $t('components.financials.remove_modal.remove_title_w_type', {
        type: $t(
          `models.visualby_configuration.config_types.${component.configType}`
        ).toLowerCase(),
      })
    "
    centered
    :ok-disabled="loading"
    ok-variant="danger"
    :ok-title="$t('buttons.titles.remove')"
    @show="fetchUsageCount"
    @ok="removeComponent"
  >
    <div v-if="loading" class="text-center">
      <be-spinner size="lg" />
    </div>

    <be-alert v-else-if="isUsed" variant="warning">
      {{ usageCountWarningText }}
    </be-alert>

    <be-alert v-else variant="info">
      {{ $t("components.financials.remove_modal.not_used") }}
    </be-alert>
  </be-modal>
</template>

<script>
import { toSentence } from "@/utils/text-utils";

export default {
  props: {
    component: {
      type: Object,
      required: true,
    },

    modalId: {
      type: String,
      required: true,
    },

    urlPrefix: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      usageCount: null,
      loading: true,
    };
  },

  computed: {
    usageCountWarningText() {
      if (!this.usageCount) {
        return;
      }

      const usage = [];

      if (this.usageCount.reports > 0) {
        usage.push(
          this.$tc(
            "components.financials.remove_modal.report_usage_plural",
            this.usageCount.reports
          )
        );
      }

      if (this.usageCount.templates > 0) {
        usage.push(
          this.$tc(
            "components.financials.remove_modal.template_usage_plural",
            this.usageCount.templates
          )
        );
      }

      if (this.usageCount.dashboards > 0) {
        usage.push(
          this.$tc(
            "components.financials.remove_modal.dashboard_usage_plural",
            this.usageCount.dashboards
          )
        );
      }

      return this.$t("components.financials.remove_modal.warning_w_usage", {
        usage: toSentence(usage, this.$i18n.locale),
      });
    },

    isUsed() {
      return (
        this.usageCount && Object.values(this.usageCount).some((v) => v > 0)
      );
    },
  },

  methods: {
    async fetchUsageCount() {
      try {
        const { data } = await axios.get(
          `${this.urlPrefix}/${this.component.uuid}`
        );

        this.usageCount = data;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    removeComponent(event) {
      event.preventDefault();
      this.loading = true;

      this.$inertia.delete(`${this.urlPrefix}/${this.component.uuid}`);
    },
  },
};
</script>
