<!--
  NOTE: This file is almost an exact copy of BeDropzone but separated into a new component to not
  add complexity to the original component. The sole purpose of this component is to upload KPI files
  to VisualBy.
  TODO: Either let VisualBy control this modal, like they do with all other uploaders for financial data,
  or figure out a way to first send the files to S3 through the BeDropzone component and then send the
  files to VisualBy in a background job, or something similar.
-->
<template>
  <be-overlay :show="uploading" rounded="sm" size="sm">
    <div
      :id="id"
      :class="computedClasses"
      tabindex="0"
      role="button"
      aria-label="$t('components.shared.be_dropzone.aria_labels.file_upload_area')"
      @dragover.prevent="onDragOver"
      @dragleave.prevent="onDragLeave"
      @drop.prevent="handleFileDrop"
      @keyup.enter="onEnterPress"
    >
      <div class="d-flex flex-wrap justify-content-center gap-2">
        <div class="d-flex align-items-center gap-2">
          <i class="fal fa-lg fa-cloud-upload text-muted" />

          <p class="pb-0 mb-0">
            {{ $t("components.shared.be_dropzone.drag_and_drop_or") }}
          </p>
        </div>

        <div class="d-flex flex-wrap justify-content-center gap-2">
          <be-button
            variant="primary"
            class="py-0 px-1"
            @click="$refs.fileInput.click()"
          >
            {{
              isMobile
                ? $t(
                    "components.shared.be_dropzone.select_file_from_your_device"
                  )
                : $t("components.shared.be_dropzone.select_file")
            }}
          </be-button>
        </div>
      </div>

      <!-- Shows allowed file types if any are specified -->
      <div class="text-center">
        <p
          v-if="acceptedFileTypes.length > 0"
          class="d-block m-0 small text-muted"
        >
          {{
            $t("components.shared.be_dropzone.allowed_types", {
              types: acceptedFileTypes.join(", "),
            })
          }}
        </p>
      </div>

      <!-- Displays error message if a validation fails -->
      <div v-if="invalid" class="dropzone-invalid-feedback">
        <i class="fas fa-lg fa-hexagon-exclamation text-danger" />

        <p v-dompurify-html="invalidMessage" class="pb-0 mb-0" />

        <i
          class="fas fa-times"
          role="button"
          :aria-label="
            $t('components.shared.be_dropzone.aria_labels.close_error_message')
          "
          @click="() => (invalid = false)"
        />
      </div>

      <!-- Hidden file input for selecting files -->
      <input
        :id="`${id}-file-input`"
        ref="fileInput"
        type="file"
        :accept="acceptedFileTypesAsMime.join(', ')"
        multiple
        hidden
        @change="handleFileSelect"
      />
    </div>
  </be-overlay>
</template>

<script>
import axios from "axios";
import { ALLOWED_TYPES, ALLOWED_TYPES_MIME } from "@/constants/file-types";
import { generateId } from "@/utils/id";
import isMobile from "is-mobile";

export default {
  props: {
    id: {
      type: String,
      required: false,
      default: () => generateId("financial-file-uploader"),
    },

    variant: {
      type: String,
      required: true,

      // This component only works for the "kpi" variant
      validator(value) {
        return value === "kpi";
      },
    },
  },

  data() {
    return {
      dragHover: false, // Tracks if a file is being dragged over the dropzone
      fileInput: null, // Reference to the file input element
      uploading: false, // Indicates if the file(s) are being uploaded
      invalid: false, // Indicates if the current file(s) are invalid
      invalidMessage: null, // Error message to display when file(s) are invalid
      errors: [], // List of errors to display from VisualBy

      acceptedFileTypes: ["xls", "xlsx"],
      maxFiles: 100,
      maxFileSize: 1024, // megabytes
    };
  },

  computed: {
    computedClasses() {
      return [
        "be-dropzone",
        {
          "drag-hover": this.dragHover,
          error: this.errors.length > 0,
          invalid: this.invalid,
        },
      ];
    },

    acceptedFileTypesAsMime() {
      return this.acceptedFileTypes.map((type) => ALLOWED_TYPES[type]) || [];
    },

    isMobile() {
      return isMobile();
    },

    visualByUploadKpiUrl() {
      const uuid = this.$activeFinancialsUuid;
      const apiRoot = window.visualbyConfig.apiRoot;

      return `${apiRoot}/secure/organisation/${uuid}/variable/data-variables-excel`;
    },
  },

  mounted() {
    this.fileInput = this.$refs.fileInput;
  },

  methods: {
    onDragOver() {
      // Set the drag hover state
      this.dragHover = true;
    },

    onDragLeave() {
      // Reset the drag hover state
      this.dragHover = false;
    },

    onEnterPress() {
      // Trigger the file input click event
      this.fileInput.click();
    },

    async handleFileDrop(event) {
      // Reset the drag hover state
      this.dragHover = false;

      // Validate and upload the dropped files
      await this.validateAndUploadFiles(event.dataTransfer.files);
    },

    async handleFileSelect() {
      // Validate and upload the selected files
      await this.validateAndUploadFiles(this.fileInput.files);

      // Clear the file input after the files have been uploaded
      this.fileInput.value = "";
    },

    async validateAndUploadFiles(files) {
      // Validation rules for file type and size
      const validations = [
        { rule: this.validateFileType, options: this.acceptedFileTypesAsMime },
        { rule: this.validateFileSize, options: this.maxFileSize },
      ];

      for (let file of files) {
        let errorMessage = null;

        // Apply each validation rule to the file
        for (let validation of validations) {
          errorMessage = validation.rule(file, validation.options);
          if (errorMessage) break; // Stop on the first error encountered
        }

        if (errorMessage) {
          // Show the error message
          this.invalid = true;
          this.invalidMessage = errorMessage;

          // Auto-hide the error message after 15 seconds
          // You can also close it manually by clicking the close button
          setTimeout(() => {
            this.invalid = false;
            this.invalidMessage = null;
          }, 15000);
        } else {
          // Proceed with file upload if validation passes
          await this.uploadFile(file);
        }
      }
    },

    validateFileType(file, acceptedTypes) {
      if (acceptedTypes.includes(file.type)) {
        return null;
      } else {
        return this.$t(
          "components.shared.be_dropzone.errors.invalid_file_type_html",
          {
            file: file.name,
            type: ALLOWED_TYPES_MIME[file.type] || file.type,
            allowedTypes: this.acceptedFileTypes.join(", "),
          }
        );
      }
    },

    validateFileSize(file, maxSize) {
      if (file.size <= maxSize * 1024 * 1024) {
        return null;
      } else {
        return this.$t(
          "components.shared.be_dropzone.errors.invalid_file_size_html",
          {
            file: file.name,
            size: (file.size / 1024 / 1024).toFixed(2),
            maxSize,
          }
        );
      }
    },

    async uploadFile(file) {
      // Set the uploading flag
      this.uploading = true;

      // Create a new FormData object and append the file to it
      const formData = new FormData();
      formData.append("file", file);

      try {
        await axios.post(this.visualByUploadKpiUrl, formData, {
          headers: {
            "Content-Type": file.type,
            "X-Auth-Token": window.wzGetController().getCurrentToken(),
            "X-Csrf-Token": null, // Disable CSRF token for this request
          },
        });

        // Reload the page to display the uploaded file
        window.location.reload();
      } catch (error) {
        // Reset the uploading flag
        this.uploading = false;

        // Show a generic error message if the upload fails
        this.invalid = true;
        this.invalidMessage = this.$t(
          "views.companies.economy.modals.upload_file.errors.unknown"
        );

        // Auto-hide the error message after 15 seconds
        // You can also close it manually by clicking the close button
        setTimeout(() => {
          this.invalid = false;
          this.invalidMessage = null;
        }, 15000);
      }
    },
  },
};
</script>
