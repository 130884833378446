import { mapGetters } from "vuex";

export const documentArchiveNavigation = {
  data() {
    return {
      selectedItems: {
        documents: [],
        folders: [],
      },

      loading: null,
    };
  },

  computed: {
    ...mapGetters({
      currentFolder: "documents/getCurrentFolder",
      folders: "documents/getFolders",
      documents: "documents/getDocumentsInFolder",
    }),
  },

  methods: {
    async loadFolder(folder, options = {}) {
      this.loading = true;

      if (this.flipperFlag("documents_inertia_navigation") && this.$inertia) {
        if (!folder?.id) {
          this.$inertia.get(this.url("/documents"));
        } else {
          this.$inertia.get(folder.paths.base);
        }
      } else {
        const url = await this.$store.dispatch(
          `documents/selectFolder`,
          folder
        );
        // Optionally set the navigation history state, used e.g in the document archive.
        if (options.pushState) {
          window.history.pushState({ folderId: folder?.id || null }, "", url);
        }
        this.loading = false;
        this.resetSelectedItems();
        this.resetExports();
        this.$store.commit("documents/highlightFolder", null);
      }
    },

    handleSelect(item, scope) {
      const collection = this.selectedItems[scope];
      const idx = collection.findIndex((localItem) => localItem.id === item.id);

      if (idx > -1) {
        collection.splice(idx, 1);
      } else {
        collection.push(item);
      }
    },

    resetSelectedItems() {
      this.selectedItems = {
        documents: [],
        folders: [],
      };
    },

    resetExports() {
      // Clear the documentExports array when the folder is changed. We need to check
      // if the property exists because it's only defined in the DocumentList.vue component
      // and this mixin is also used in the DocumentPickerModal.vue.
      if (this.documentExports) {
        this.documentExports = [];
      }
    },
  },
};
