<template>
  <div>
    <be-tabs
      v-if="!onlyShowPdfTab && mode != 'reports-overview'"
      v-model="activeTab"
      :options="reportTabsDataShow(report)"
    >
      <template #content-right>
        <report-options
          :report="report"
          :versions="versions"
          :controller-action="controllerAction"
        />
      </template>
    </be-tabs>

    <div v-if="newerVersionExists" class="card">
      <div class="card-header border-bottom-0 rounded">
        <be-alert variant="warning" class="font-weight-normal small mb-0">
          {{ $t("views.companies.reports.show.newer_version_exists") }}
        </be-alert>
      </div>
    </div>

    <report-attachments-view v-if="tab === 'attachments'" :report="report" />

    <report-participants v-show="tab === 'participants'" :report="report" />

    <report-pdf-tab
      v-show="tab === 'pdf'"
      :controller-action="controllerAction"
      :report="report"
    />

    <template v-if="tab === 'report'">
      <div class="card mb-2">
        <div class="card-body">
          <report-view :report="report" auto-read />
        </div>

        <div v-if="!report.published_at" class="card-footer text-right">
          <report-publish-button :report="report" />
        </div>
      </div>

      <div class="card mb-2">
        <div class="card-header">
          <h5 class="card-title">
            {{ $t("views.companies.reports.show.comments_title") }}
          </h5>
        </div>

        <div class="card-body p-0">
          <be-alert
            v-if="report.comments.length === 0 && newerVersionExists"
            variant="info"
            class="mb-0"
          >
            {{ $t("views.companies.reports.show.no_comments") }}
          </be-alert>

          <comments-list
            :comments="report.comments"
            :commentable-object="report"
            :activities="report.activities"
            :readonly="newerVersionExists"
          />
        </div>
      </div>
    </template>

    <div v-if="report.published_at && tab === 'readings'" class="card">
      <div class="card-header">
        <h5 class="card-title">
          {{ $t("activerecord.models.reading.other") }}
        </h5>
      </div>

      <div class="card-body">
        <report-readings-table :report="report" />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const reportHelpers = createNamespacedHelpers("reports");

import { reportTabsDataShow } from "@/utils/reports";

import ReportAttachmentsView from "./ReportAttachmentsView.vue";
import ReportOptions from "./ReportOptions.vue";
import ReportParticipants from "./ReportParticipants.vue";
import ReportPdfTab from "./ReportPdfTab.vue";
import ReportPublishButton from "./ReportPublishButton.vue";
import ReportView from "@/components/reports/ReportView.vue";
import ReportReadingsTable from "@/components/reports/ReportReadingsTable.vue";

export default {
  components: {
    ReportAttachmentsView,
    ReportOptions,
    ReportParticipants,
    ReportPdfTab,
    ReportPublishButton,
    ReportReadingsTable,
    ReportView,
  },

  props: {
    report: {
      type: Object,
      required: true,
    },

    versions: {
      type: Array,
      required: false,
      default: () => [],
    },

    controllerAction: {
      type: String,
      required: true,
    },

    mode: {
      type: String,
      required: false,
      default: "report",
    },

    forceActiveTab: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      activeTab: "report",
    };
  },

  computed: {
    onlyShowPdfTab() {
      return (
        this.$currentMembership.policy_level === "limited" &&
        this.platformFeature("report_limited_user_only_pdf")
      );
    },

    tab() {
      return this.forceActiveTab || this.activeTab;
    },

    newerVersionExists() {
      if (!this.report.published_at) {
        return false;
      }

      return this.versions.some((version) => {
        return version.published_at && version.id > this.report.id;
      });
    },
  },

  mounted() {
    this.fetchReportPdf(this.report);
    if (this.onlyShowPdfTab) {
      this.activeTab = "pdf";
    }
  },

  methods: {
    ...reportHelpers.mapActions(["fetchReportPdf"]),
    reportTabsDataShow,
  },
};
</script>
