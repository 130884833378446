<template>
  <div>
    {{ !expired ? formattedDuration : expirationMessage }}
  </div>
</template>

<script>
import { formatDuration, intervalToDuration } from "date-fns";
import locales from "@/config/locales";

export default {
  props: {
    endDate: {
      // Date, ISO string or timestamp
      type: [Date, String, Number],
      required: true,
    },

    expirationMessage: {
      type: String,
      required: false,
      default: "",
    },

    formatOptions: {
      type: Object,
      required: false,

      default: () => ({
        format: ["years", "months", "days", "hours", "minutes"],
      }),
    },
  },

  emits: ["expired"],

  data() {
    return {
      now: new Date(),
      localEndDate: this.formatDate(this.endDate),
    };
  },

  computed: {
    formattedDuration() {
      const duration = intervalToDuration({
        start: this.now,
        end: this.localEndDate,
      });

      return formatDuration(duration, {
        ...this.formatOptions,
        locale: locales[this.$currentUser.locale],
      });
    },

    expired() {
      return this.now >= this.localEndDate;
    },
  },

  watch: {
    expired() {
      if (this.expired) {
        clearInterval(this.interval);
        this.$emit("expired");
      }
    },
  },

  mounted() {
    this.interval = setInterval(() => {
      this.now = new Date();
    }, 1000);
  },

  methods: {
    formatDate(date) {
      return date instanceof Date ? date : new Date(date);
    },
  },
};
</script>
