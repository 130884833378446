<template>
  <div :id="uid" ref="sie-table"></div>
</template>

<script>
export default {
  data() {
    return {
      initialized: false,
    };
  },

  computed: {
    uid() {
      return `sie-table-${this.$.uid}`;
    },
  },

  mounted() {
    this.waitForFinancials(this.initializeComponent);
  },

  methods: {
    initializeComponent() {
      if (this.initialized) {
        return;
      }

      this.initialized = true;

      window
        .wzGetController()
        .addSieFiles(`#${this.uid}`, this.$activeFinancialsUuid);
    },
  },
};
</script>
