<template>
  <be-modal
    :id="id"
    :title="title"
    ok-only
    ok-variant="light"
    :ok-title="$t('buttons.titles.close')"
    @show="modalOpened"
    @hide="modalHidden"
  >
    <export-form
      v-if="!exportCase && !loading"
      :export-types="exportTypes"
      :filter-types="filterTypes"
      :preselected-options="preselectedOptions"
      @export="requestExport"
    >
      <template #filters>
        <slot name="filters" />
      </template>
    </export-form>

    <template v-else>
      <!-- Loading any previous export -->
      <template v-if="loading && !exportInProgress">
        <!-- Show nothing because the UI will get jumpy if we show a loading spinner since it loads too fast -->
      </template>

      <!-- Export failed -->
      <be-alert v-else-if="exportFailed" variant="danger" class="mb-0">
        {{ $t("components.shared.export_modal.export_failed") }}.

        <be-link @click="requestExport">
          {{ $t("buttons.titles.try_again") }}?
        </be-link>
      </be-alert>

      <!-- Export is processing or is finished -->
      <div v-else class="d-flex flex-column">
        <exported-file
          :key="exportCase.id"
          :export-case="exportCase"
          @request-export="requestExport"
        />

        <!-- Export is finished -->
        <div
          v-if="showStatusText"
          class="d-flex align-items-center pl-1 text-muted mt-2"
        >
          {{ statusText }}.

          <be-button
            variant="link"
            class="p-0 ml-1 text-reset-line-height"
            @click="clearExport"
          >
            {{ `${$tc("buttons.titles.create_new_export", 1)}?` }}
          </be-button>
        </div>
      </div>
    </template>
  </be-modal>
</template>

<script>
import ExportedFile from "./ExportedFile.vue";
import ExportForm from "./ExportForm.vue";

// Clear previous export if it is older than 10 minutes
const VALID_FOR_MINUTES = 10;

export default {
  components: {
    ExportedFile,
    ExportForm,
  },

  props: {
    exportTypes: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    id: {
      type: String,
      required: true,
    },

    filterTypes: {
      type: Array,
      required: false,
      default: () => [],
    },

    exportParams: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    preselectedOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      chosenExportType: null,
      chosenFilterType: null,
      exportCase: null,
      interval: null,
      loading: true,
      now: new Date(),
      updateNowInterval: null,
    };
  },

  computed: {
    exportFailed() {
      return this.exportCase && this.exportCase.status === "failed";
    },

    exportInProgress() {
      return (
        this.exportCase &&
        ["pending", "processing"].includes(this.exportCase.status)
      );
    },

    minutesSinceExport() {
      if (!this.exportCase) return 1;

      const diff = Math.abs(
        new Date(this.exportCase.created_at) - new Date(this.now)
      );

      return Math.floor(diff / 1000 / 60);
    },

    showStatusText() {
      return this.exportCase && !this.exportInProgress;
    },

    statusText() {
      return this.$tc(
        "components.shared.export_modal.export_created_w_minutes",
        this.minutesSinceExport,
        {
          minutes: this.minutesSinceExport,
        }
      );
    },
  },

  watch: {
    exportInProgress() {
      if (this.exportInProgress) {
        this.startPolling();
      } else {
        this.stopPolling();
      }
    },
  },

  beforeUnmount() {
    this.stopPolling();
    clearInterval(this.updateNowInterval);
    this.updateNowInterval = null;
  },

  methods: {
    async requestExport({ exportType, filterType }) {
      this.chosenExportType = exportType;
      this.chosenFilterType = filterType;
      this.exportCase = null;
      this.previousExport = null;

      try {
        const { data } = await axios.post(this.url("exports"), {
          export: {
            export_type: exportType,
            filter_type: filterType,
            ...this.exportParams,
          },
        });

        this.exportCase = data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async fetchExport(sentryLog = false) {
      this.loading = true;

      try {
        const { data } = await axios.get(this.url("exports"), {
          params: {
            export_types: [
              this.chosenExportType || this.preselectedOptions.exportType,
            ],
          },
        });

        this.exportCase = data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )[0];

        if (this.minutesSinceExport > VALID_FOR_MINUTES) {
          this.clearExport();
        }
      } catch (error) {
        this.handleError(error, { sentryLog });
      } finally {
        this.loading = false;
      }
    },

    clearExport() {
      this.chosenExportType = null;
      this.chosenFilterType = null;
      this.exportCase = null;
    },

    startPolling() {
      if (this.interval === null) {
        this.interval = setInterval(this.fetchExport, 3000);
      }
    },

    stopPolling() {
      clearInterval(this.interval);
      this.interval = null;
    },

    async modalOpened() {
      await this.fetchExport(true);

      if (this.exportInProgress) {
        this.startPolling();
      }

      // Update the time every 10 seconds to keep the "minutes ago" text up to date
      this.updateNowInterval = setInterval(() => {
        this.now = new Date();
      }, 1000 * 10); // 10 seconds
    },

    modalHidden() {
      this.stopPolling();
      clearInterval(this.updateNowInterval);
      this.updateNowInterval = null;
    },
  },
};
</script>
