<template>
  <be-button
    variant="outline-primary"
    :disabled="loading"
    @click="$beModal.show(modalId)"
  >
    {{ $t("components.reports.participants.publish_report") }}
  </be-button>

  <invitations-send
    :invitations="invitationsForInviting"
    :modal-id="modalId"
    :modal-title="$t('components.reports.participants.publish_report')"
    :modal-confirm="$t('components.reports.participants.publish_report')"
    :checked-ids="checkedIds"
    :loading="publishing"
    allow-empty
    @send="handlePublish"
    @update:message="(event) => (message = event)"
  >
    <template #description>
      <be-alert variant="info">
        {{ $t("components.reports.publish.description") }}
      </be-alert>

      <h5 class="mb-0">
        {{ $t("components.reports.publish.invite_and_notify") }}
      </h5>
    </template>

    <template #options>
      <be-form-group :disabled="message.length <= 0">
        <be-form-checkbox v-model="saveAsComment">
          {{ $t("components.reports.publish.save_as_comment") }}
        </be-form-checkbox>
      </be-form-group>
    </template>
  </invitations-send>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";

import InvitationsSend from "@/components/companies/InvitationsSend.vue";

const companyHelpers = createNamespacedHelpers("company");
const reportHelpers = createNamespacedHelpers("reports");

export default {
  components: {
    InvitationsSend,
  },

  props: {
    report: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modalId: `publish-modal-${this.generateUuid()}`,
      publishing: false,
      loading: false,
      saveAsComment: false,
      message: "",
    };
  },

  computed: {
    ...companyHelpers.mapGetters({
      memberships: "memberships",
    }),

    ...mapGetters("invitations", ["invitationsForReference"]),

    invitations() {
      return this.invitationsForReference("Report", this.report.id);
    },

    invitationsForInviting() {
      return this.invitations.concat(
        this.memberships
          .filter((membership) => !this.invitedUserIds.has(membership.user.id))
          .map((membership) => ({
            user_id: membership.user.id,
          }))
      );
    },

    checkedIds() {
      const ids = this.invitedUserIds;

      this.memberships
        .filter(
          (membership) =>
            ["admin", "observer"].includes(membership.policy_level) ||
            membership.role === "cfo"
        )
        .forEach((membership) => ids.add(membership.user.id));

      return Array.from(ids);
    },

    invitedUserIds() {
      return new Set(this.invitations.map((invitation) => invitation.user_id));
    },
  },

  methods: {
    ...reportHelpers.mapActions(["publish"]),

    async handlePublish({ invitations, message }) {
      try {
        this.publishing = true;

        await this.publish({
          report: this.report,

          parameters: {
            message: message,
            user_ids: invitations.map((invitation) => invitation.user_id),
          },
        });

        if (this.saveAsComment) {
          const comment_body = {
            body: message,
            commented_type: "Report",
            commented_id: this.report.id,
          };

          await axios.post(
            this.url("/comments", {
              nanoid: this.$currentCompany.id,
            }),
            {
              comment: comment_body,
            }
          );
        }

        window.location = this.report.paths.base;
      } catch (error) {
        this.handleError(error);
        this.publishing = false;
      }
    },
  },
};
</script>
