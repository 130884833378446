<template>
  <div :id="id" ref="dropdown" :class="dropdownClasses">
    <template v-if="split">
      <component
        :is="splitButtonTag"
        ref="split"
        :href="splitHref || '#'"
        :role="splitHref ? null : splitButtonType"
        :class="[splitButtonClasses, splitClass]"
        @click="onSplitClick"
      >
        <i v-if="ellipsis" class="fa fa-ellipsis-h text-muted" />

        <slot v-else name="button-content">
          {{ text }}
        </slot>
      </component>

      <button
        v-bind="toggleAttrs"
        ref="toggle"
        :class="[buttonClasses, toggleClasses]"
        :disabled="disabled"
        :aria-haspopup="ariaHasPopupRoles.includes(role) ? role : 'false'"
        aria-expanded="false"
        @mousedown="onMousedown"
        @click="toggleDropdown"
        @keydown="toggleDropdown"
      >
        <span class="sr-only">
          {{ toggleText || $t("components.shared.be_dropdown.toggle_sr_text") }}
        </span>
      </button>
    </template>

    <button
      v-else
      v-bind="toggleAttrs"
      ref="toggle"
      :class="[buttonClasses, toggleClasses]"
      :disabled="disabled"
      aria-expanded="false"
      @mousedown="onMousedown"
      @click="toggleDropdown"
      @keydown="toggleDropdown"
    >
      <div v-if="ellipsis">
        <i class="fa fa-ellipsis-h" />
      </div>

      <slot v-else name="button-content">
        {{ text }}
      </slot>
    </button>

    <ul
      v-if="renderMenu"
      ref="menu"
      v-click-outside="onClickOutside"
      :class="['dropdown-menu', menuClasses]"
      :role="role"
      tabindex="-1"
      :aria-labelledby="`_BE_${split ? 'button' : 'toggle'}_${id}`"
      @keydown="onKeydown"
    >
      <slot />
    </ul>
  </div>
</template>

<script>
import Popper from "popper.js";
import { merge } from "lodash";
import { generateId } from "@/utils/id";
import { EventBus } from "@/event-bus";
import {
  KEY_CODE_ESCAPE,
  KEY_CODE_DOWN,
  KEY_CODE_UP,
  KEY_CODE_ENTER,
  KEY_CODE_SPACE,
} from "@/constants/key-codes";

export default {
  name: "BeDropdown",

  props: {
    block: {
      type: Boolean,
      required: false,
      default: false,
    },

    boundary: {
      type: [String, HTMLElement],
      required: false,
      default: "scrollParent",
    },

    buttonClass: {
      type: [Array, Object, String],
      required: false,
      default: null,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    dropleft: {
      type: Boolean,
      required: false,
      default: false,
    },

    dropright: {
      type: Boolean,
      required: false,
      default: false,
    },

    dropup: {
      type: Boolean,
      required: false,
      default: false,
    },

    ellipsis: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,

      default: () => generateId("be-dropdown"),
    },

    inline: {
      type: Boolean,
      required: false,
      default: false,
    },

    lazy: {
      type: Boolean,
      required: false,
      default: false,
    },

    menuClass: {
      type: [Array, Object, String],
      required: false,
      default: null,
    },

    noCaret: {
      type: Boolean,
      required: false,
      default: false,
    },

    noFlip: {
      type: Boolean,
      required: false,
      default: false,
    },

    offset: {
      type: [Number, String],
      required: false,
      default: 0,
    },

    popperOpts: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    right: {
      type: Boolean,
      required: false,
      default: false,
    },

    role: {
      type: String,
      required: false,
      default: "menu",
    },

    size: {
      type: String,
      required: false,
      default: null,
    },

    split: {
      type: Boolean,
      required: false,
      default: false,
    },

    splitButtonType: {
      type: String,
      required: false,
      default: "button",
    },

    splitClass: {
      type: [Array, Object, String],
      required: false,
      default: null,
    },

    splitHref: {
      type: String,
      required: false,
      default: null,
    },

    splitVariant: {
      type: String,
      required: false,
      default: null,
    },

    text: {
      type: String,
      required: false,
      default: null,
    },

    toggleAttrs: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    toggleClass: {
      type: [Array, Object, String],
      required: false,
      default: null,
    },

    toggleText: {
      type: String,
      required: false,
      default: null,
    },

    variant: {
      type: String,
      required: false,
      default: "outline-secondary",
    },
  },

  emits: ["show", "shown", "hide", "hidden", "toggle", "click"],

  data() {
    return {
      ariaHasPopupRoles: ["menu", "listbox", "tree", "grid", "dialog"],
      isMounted: false,
      localBoundaryClass: null,
      visible: false,
    };
  },

  computed: {
    dropdownClasses() {
      const { block, disabled, ellipsis, inline, split, visible } = this;

      return [
        "dropdown",
        this.directionClass,
        this.boundaryClass,
        this.localBoundaryClass,
        {
          disabled,
          show: visible,
          "d-flex": !inline,
          "d-inline-flex": inline,

          // The 'btn-group' class is required in `split` mode for button alignment
          // It needs also to be applied when `block` is disabled to allow multiple
          // dropdowns to be aligned on one line
          "btn-group": split || !block,

          // Add the `be-dropdown-ellipsis` class to the dropdown if `ellipsis` is enabled
          "be-dropdown-ellipsis": ellipsis,

          // If we're not in `block` or `split` mode, we need to add the `d-md-inline-flex`
          // to make the button not take up the entire width in larger screen sizes.
          "d-md-inline-flex": !block || !split,

          // Add margin to the left on screens larger than "md" if `ellipsis` is enabled,
          // but not when `block` is enabled as the buttons will stretch to full width
          "ml-md-2": !block && ellipsis,
        },
      ];
    },

    menuClasses() {
      const { ellipsis, right } = this;

      return [
        this.menuClass,
        {
          "dropdown-menu-right": right || ellipsis,
          show: this.visible,
        },
      ];
    },

    splitButtonClasses() {
      const { block, size, splitVariant, variant } = this;

      return [
        "btn",
        {
          "btn-block": block,
          [`btn-${size}`]: size,
          [`btn-${splitVariant || variant}`]: splitVariant || variant,
        },
      ];
    },

    buttonClasses() {
      const { block, size, split, variant, buttonClass } = this;

      return [
        "btn",
        {
          "btn-block": block && !split,
          [`btn-${size}`]: size,
          [`btn-${variant}`]: variant,
        },
        buttonClass,
      ];
    },

    toggleClasses() {
      const { ellipsis, noCaret, split } = this;

      return [
        "dropdown-toggle",
        this.toggleClass,
        {
          "dropdown-toggle-split": split,
          "dropdown-toggle-no-caret": (noCaret || ellipsis) && !split,
        },
      ];
    },

    boundaryClass() {
      // Position `static` is needed to allow menu to "breakout" of the `scrollParent`
      // boundaries when boundary is anything other than `scrollParent`
      // See: https://github.com/twbs/bootstrap/issues/24251#issuecomment-341413786
      return this.boundary !== "scrollParent" ? "position-static" : "";
    },

    directionClass() {
      const { dropup, dropright, dropleft } = this;

      if (dropup) {
        return "dropup";
      } else if (dropright) {
        return "dropright";
      } else if (dropleft) {
        return "dropleft";
      }

      return "";
    },

    splitButtonTag() {
      const { splitHref } = this;

      return splitHref ? "a" : "button";
    },

    renderMenu() {
      return this.lazy ? this.visible : this.isMounted;
    },
  },

  created() {
    this.$_popper = null;
  },

  mounted() {
    this.isMounted = true;
  },

  methods: {
    show() {
      this.showMenu();
    },

    hide(refocus = false) {
      this.hideMenu();

      if (refocus) {
        this.$refs.toggle.focus();
      }
    },

    showMenu() {
      if (this.disabled) {
        return;
      }

      if (typeof Popper === "undefined") {
        console.warn("Popper.js not found. Falling back to CSS positioning.");
      } else {
        // For dropup with alignment we use the parent element as popper container
        let el =
          (this.dropup && (this.right || this.ellipsis)) || this.split
            ? this.$el
            : this.$refs.toggle;

        // Make sure we have a reference to an element, not a component.
        el = el.$el || el;

        // Set the visible state
        this.visible = true;

        // Instantiate Popper.js
        this.createPopper(el);
      }

      // Emit root event that dropdown is about to be shown
      EventBus.emit("be::dropdown::show", this);

      // Emit the show event
      this.$emit("show", this);

      // Wrap in `$nextTick()` to ensure menu is fully rendered/shown
      this.$nextTick(() => {
        // Focus on the menu container on show
        this.$refs.menu.focus({ preventScroll: true });

        // Emit the shown event
        this.$emit("shown");
      });

      // Enable listeners to close other dropdowns that might be open
      EventBus.on("be::dropdown::show", (dropdown) => {
        if (dropdown !== this) {
          this.hideMenu();
        }
      });
    },

    hideMenu() {
      // Emit root event that dropdown is about to be hidden
      EventBus.emit("be::dropdown::hide", this);

      // Emit the hide event
      this.$emit("hide", this);

      // Set the visible state
      this.visible = false;

      // Ensure popper event listeners are removed cleanly
      this.destroyPopper();

      // Disable listeners
      EventBus.off("be::dropdown::shown");

      // Emit the hidden event
      this.$emit("hidden");
      EventBus.emit("be::dropdown::hidden", this.id);
    },

    createPopper(element) {
      this.destroyPopper();
      this.$_popper = new Popper(
        element,
        this.$refs.menu,
        this.getPopperConfig(element)
      );
    },

    // Ensure popper event listeners are removed cleanly
    destroyPopper() {
      if (this.$_popper) {
        this.$_popper.destroy();
        this.$_popper = null;
      }
    },

    getPopperConfig(element) {
      const { dropup, dropright, dropleft, ellipsis, right } = this;

      let placement = "bottom-start";

      if (dropup) {
        placement = right ? "top-end" : "top-start";
      } else if (dropright) {
        placement = "right-start";
      } else if (dropleft) {
        placement = "left-start";
      } else if (right || ellipsis) {
        placement = "bottom-end";
      }

      const popperConfig = {
        placement,

        modifiers: {
          offset: { offset: this.offset || 0 },
          flip: { enabled: !this.noFlip },
        },
      };

      const boundariesElement = this.getBoundaryElement(element);

      if (boundariesElement) {
        if (boundariesElement !== "scrollParent") {
          // Position `static` is needed to allow menu to "breakout" of the `scrollParent`
          // boundaries when boundary is anything other than `scrollParent`
          // See: https://github.com/twbs/bootstrap/issues/24251#issuecomment-341413786
          this.localBoundaryClass = "position-static";
        }

        popperConfig.modifiers.preventOverflow = { boundariesElement };
      }

      return merge({}, popperConfig, this.popperOpts);
    },

    getBoundaryElement(element) {
      if (this.boundary === "scrollParent") {
        // Find out if a parent element has the overflow, overflow-x or overflow-y property set to auto, scroll or overlay,
        // which would indicate the use of a scrollable container as boundary, meaning the dropdown menu would be cut off.
        // If such a parent element is found, we use the viewport as boundary instead.
        let parent = element.parentNode;

        while (parent && parent.tagName !== "BODY") {
          const overflowY = window.getComputedStyle(parent).overflowY;
          const overflowX = window.getComputedStyle(parent).overflowX;
          const overflow = window.getComputedStyle(parent).overflow;

          const overflowValues = [overflowY, overflowX, overflow];

          if (
            overflowValues.includes("auto") ||
            overflowValues.includes("scroll") ||
            overflowValues.includes("overlay")
          ) {
            return "viewport";
          }

          parent = parent.parentNode;
        }
      }

      return this.boundary;
    },

    onClickOutside() {
      if (this.visible) {
        this.hideMenu();
      }
    },

    onKeydown(event) {
      const { keyCode } = event;

      if (keyCode === KEY_CODE_ESCAPE) {
        // Close on ESC
        this.onEsc(event);
      } else if (keyCode === KEY_CODE_DOWN) {
        // Down Arrow
        this.focusNext(event, false);
      } else if (keyCode === KEY_CODE_UP) {
        // Up Arrow
        this.focusNext(event, true);
      }
    },

    onEsc(event) {
      event.preventDefault();
      event.stopPropagation();

      // Return focus to original trigger button
      EventBus.once("be::dropdown::hidden", (id) => {
        if (id === this.id) {
          this.$refs.toggle.focus();
        }
      });

      this.hideMenu();
    },

    onMousedown(event) {
      // We prevent the mousedown event from triggering the focusin
      // event on the toggle button. This is because the focusin event
      // can cause the menu to show.
      event.preventDefault();
      event.stopPropagation();
    },

    onSplitClick(event) {
      if (!this.splitHref) {
        event.preventDefault();
        event.stopPropagation();
      }

      this.$emit("click", event);
    },

    toggleDropdown(event) {
      event = event || {};

      const { type, keyCode } = event;

      if (
        type !== "click" &&
        !(
          type === "keydown" &&
          [KEY_CODE_ENTER, KEY_CODE_SPACE, KEY_CODE_DOWN].includes(keyCode)
        )
      ) {
        return;
      }

      if (this.disabled) {
        return;
      }

      this.$emit("toggle", event);

      event.preventDefault();
      event.stopPropagation();

      if (this.visible) {
        this.hideMenu();
      } else {
        this.showMenu();
      }
    },

    focusNext(event, up) {
      const { target } = event;

      // Ignore key up/down on form elements
      if (target.closest && target.closest(".dropdown form")) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      this.$nextTick(() => {
        const items = this.getItems();

        if (items.length < 1) {
          return;
        }

        let index = items.indexOf(target);

        if (up && index > 0) {
          index--;
        } else if (!up && index < items.length - 1) {
          index++;
        }

        if (index < 0) {
          index = 0;
        }

        items[index].focus();
      });
    },

    getItems() {
      return Array.from(
        this.$refs.menu.querySelectorAll(
          ".dropdown-item:not(.disabled):not([disabled])"
        )
      );
    },
  },
};
</script>
