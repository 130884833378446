<template>
  <div class="card">
    <div class="card-body">
      <invitations-show
        :invited="displayInvitations"
        :owners="invitationOwners"
        :always-invited="alwaysInvitedUsers.map((user) => user.id)"
        :invite-policy="invitationPolicy"
        @remind="showReminderModal"
        @uninvite="uninvite"
      />

      <div class="my-2 d-md-flex justify-content-between">
        <be-button
          v-if="invitationPolicy"
          variant="outline-secondary"
          @click="showInvitationsModal"
        >
          {{ inviteMoreTitle }}
        </be-button>
      </div>

      <invitations-send
        :key="modalKey"
        :invitations="invitationsForInviting"
        :modal-id="`report-${report.id}-invitations`"
        :modal-title="inviteTitle"
        :modal-confirm="modalConfirm"
        :checked-ids="
          reminderInvitation !== null ? [reminderInvitation.user_id] : []
        "
        :locked="reminderInvitation !== null"
        :loading="sending"
        @send="sendInvites"
      />
    </div>

    <div v-if="!report.published_at" class="card-footer text-right">
      <report-publish-button :report="report" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus";

import InvitationsShow from "@/components/companies/InvitationsShow.vue";
import InvitationsSend from "@/components/companies/InvitationsSend.vue";
import ReportPublishButton from "./ReportPublishButton.vue";

export default {
  components: {
    InvitationsShow,
    InvitationsSend,
    ReportPublishButton,
  },

  props: {
    report: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sending: false,
      reminderInvitation: null,
      modalKey: this.generateUuid(),
    };
  },

  computed: {
    ...mapGetters({
      company: "company/getCompany",
      memberships: "company/memberships",
    }),

    ...mapGetters("invitations", ["invitationsForReference"]),

    invitations() {
      return this.invitationsForReference("Report", this.report.id) || [];
    },

    displayInvitations() {
      const alwaysInvited = this.alwaysInvitedUsers
        .filter((user) => !this.invitedUserIds.has(user.id))
        .map((user) => ({
          user_id: user.id,
        }));

      return this.invitations.concat(alwaysInvited);
    },

    invitationsForInviting() {
      if (this.reminderInvitation !== null) {
        return [this.reminderInvitation];
      }

      return this.invitations.concat(
        this.memberships
          .filter((membership) => !this.invitedUserIds.has(membership.user.id))
          .map((membership) => ({
            user_id: membership.user.id,
          }))
      );
    },

    invitedUserIds() {
      return new Set(this.invitations.map((invitation) => invitation.user_id));
    },

    invitationOwners() {
      const owner = this.getUser(this.report.creator_id);
      if (owner) {
        return [owner];
      } else {
        return [];
      }
    },

    invitationPolicy() {
      return this.report?.policy?.invite || false;
    },

    alwaysInvitedUsers() {
      if (this.report.published_at) {
        return this.memberships
          .filter(
            (membership) =>
              ["admin", "observer"].includes(membership.policy_level) ||
              membership.role === "cfo"
          )
          .map((membership) => membership.user);
      } else {
        return [];
      }
    },

    inviteTitle() {
      if (
        this.reminderInvitation &&
        !this.reminderInvitation.deleted_at &&
        this.reminderInvitation.created_at
      ) {
        return this.$t("buttons.titles.remind");
      } else if (this.report && this.report.published_at != null) {
        return this.$t("components.reports.participants.invite_to_read");
      } else {
        return this.$t("components.reports.participants.invite_to_edit");
      }
    },

    modalConfirm() {
      if (
        this.reminderInvitation &&
        !this.reminderInvitation.deleted_at &&
        this.reminderInvitation.created_at
      ) {
        return this.$t("buttons.titles.remind");
      } else {
        return this.$t("buttons.titles.invite");
      }
    },

    inviteMoreTitle() {
      if (this.report && this.report.published_at != null) {
        return this.$t("components.reports.participants.invite_more_to_read");
      } else {
        return this.$t("components.reports.participants.invite_more_to_edit");
      }
    },
  },

  watch: {
    async report(value) {
      if (value?.id) {
        await this.fetchInvitations(value);

        this.updateBadge();
      }
    },
  },

  async mounted() {
    if (this.report?.id) {
      await this.fetchInvitations(this.report);

      this.updateBadge();
    }
  },

  methods: {
    ...mapActions("invitations", [
      "fetchInvitations",
      "createInvitation",
      "deleteInvitation",
    ]),

    showReminderModal(invitation) {
      this.reminderInvitation = invitation;
      this.$beModal.show(`report-${this.report.id}-invitations`);
    },

    showInvitationsModal() {
      this.reminderInvitation = null;
      this.$beModal.show(`report-${this.report.id}-invitations`);
    },

    async sendInvites({ invitations, message }) {
      this.sending = true;

      const requests = invitations.map((invitation) =>
        this.createInvitation({
          resource: this.report,

          invitation: {
            user_id: invitation.user_id,
            message: message,
          },
        })
      );

      await Promise.all(requests);

      this.sending = false;
      this.modalKey = this.generateUuid();
      this.reminderInvitation = null;

      this.updateBadge();
    },

    confirmUninviteText(invitation) {
      if (
        invitation.user_id === this.$currentUser.id &&
        this.report.published_at
      ) {
        return this.$t(
          "components.reports.participants.confirm_uninvite_self_text_published"
        );
      } else if (this.report.published_at) {
        return this.$t(
          "components.reports.participants.confirm_uninvite_text_published_w_name",
          {
            name: invitation.name,
          }
        );
      } else if (invitation.user_id === this.$currentUser.id) {
        return this.$t(
          "components.reports.participants.confirm_uninvite_self_text"
        );
      } else {
        return this.$t(
          "components.reports.participants.confirm_uninvite_text_w_name",
          {
            name: invitation.name,
          }
        );
      }
    },

    async uninvite(invitation) {
      const isConfirmed = await this.promptRemovalConfirm({
        title: this.$t(
          "components.reports.participants.confirm_uninvite_title"
        ),

        description: this.confirmUninviteText(invitation),
        stayOpenAfterConfirm: invitation.user_id === this.$currentUser.id,
      });

      if (!isConfirmed) {
        return;
      }

      await this.deleteInvitation({ resource: this.report, invitation });

      if (invitation.user_id === this.$currentUser.id) {
        window.location.reload();
      }
    },

    updateBadge() {
      let participantsCount = [];

      if (this.report.published_at) {
        const admins = this.company.memberships.filter(
          (membership) => membership.policy_level == "admin"
        );

        const observers = this.company.memberships.filter(
          (membership) => membership.policy_level == "observer"
        );

        const limited_user_ids = this.company.memberships
          .filter((membership) => membership.policy_level == "limited")
          .map((membership) => membership.user.id);

        const invitations = this.invitations.filter((invitation) => {
          return (
            invitation.id &&
            !invitation.deleted_at &&
            limited_user_ids.includes(invitation.user_id)
          );
        });

        participantsCount =
          admins.length + observers.length + invitations.length;
      } else {
        participantsCount = this.invitations.filter(
          (invitation) => invitation.id && !invitation.deleted_at
        ).length;
      }

      EventBus.emit("REPORT_PARTICIPANT_BADGE_UPDATED", participantsCount);
    },
  },
};
</script>
