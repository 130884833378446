<template>
  <div v-if="report && report.id" class="card">
    <job-callback
      v-if="callback"
      :key="`job-callback-${callback.id}`"
      :callback="callback"
      @status="handleCallbackStatus"
    />

    <div
      v-if="showCardHeader"
      class="card-header font-size-base bg-transparent font-weight-normal d-flex justify-content-between align-items-center"
    >
      <save-status
        :loading="loading"
        :saving-text="savingText"
        :failed-to-save="!!failedToSaveText"
        :failed-to-save-text="failedToSaveText"
        :unsaved-changes="unsavedChanges"
        :unsaved-changes-text="unsavedChangesText"
        :last-saved-at="documentRenderedAtText"
      />

      <be-button
        v-if="pdf.status === 'not_found'"
        variant="outline-primary"
        @click="requestPdf"
      >
        {{ $t("buttons.titles.create_w_type", { type: "PDF" }) }}
      </be-button>
    </div>

    <div class="card-body">
      <be-file-viewer
        v-if="pdf.document?.id"
        :file-url="pdf.document.preview.url"
        :file-type="pdf.document.preview.content_type"
        full-height
      />

      <be-skeleton-img v-else />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
const reportHelpers = createNamespacedHelpers("reports");

import SaveStatus from "@/components/shared/SaveStatus.vue";

export default {
  components: {
    SaveStatus,
  },

  props: {
    report: {
      type: Object,
      required: true,
    },

    controllerAction: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      callbackByReference: "job_callbacks/getNewestJobCallbackByReference",
      getPdfForReport: "reports/getPdfForReport",
    }),

    pdf() {
      return this.getPdfForReport(this.report) || {};
    },

    callback() {
      return this.callbackByReference[`Report-${this.report.id}`];
    },

    pdfCreationInProgress() {
      return ["initial", "in_progress"].includes(this.pdf.status);
    },

    loading() {
      return this.pdf.status == null || this.pdfCreationInProgress;
    },

    savingText() {
      if (this.pdfCreationInProgress) {
        return this.$t("components.reports.pdf_tab.creating_pdf");
      } else if (this.pdf.status == null) {
        return this.$t("buttons.states.loading");
      }

      return null;
    },

    unsavedChanges() {
      return (
        !this.contentHashMatching ||
        (this.pdf.status === "not_found" && !this.pdf?.document?.id)
      );
    },

    unsavedChangesText() {
      if (!this.contentHashMatching) {
        return this.$t("components.reports.pdf_tab.pdf_outdated");
      } else if (this.pdf.status == "not_found") {
        return this.$t("components.reports.pdf_tab.pdf_not_found");
      }
      return null;
    },

    contentHashMatching() {
      const reportHash = this.pdf?.document?.report_content_hash;
      const documentHash = this.pdf?.document?.content_hash;
      if (!reportHash || !documentHash) {
        return true;
      }

      return reportHash === documentHash;
    },

    documentRenderedAt() {
      if (this.pdf?.document?.content_timestamp) {
        const date = new Date(this.pdf.document.content_timestamp);
        return this.$d(date, this.dateFormatIfSameDay(date));
      }

      return null;
    },

    documentRenderedAtText() {
      if (this.documentRenderedAt) {
        return this.$t("components.reports.pdf_tab.pdf_created_at_w_time", {
          time: this.documentRenderedAt,
        });
      }

      return null;
    },

    failedToSaveText() {
      if (this.pdf?.status == "failed") {
        return this.$t("components.reports.pdf_tab.failed_creating_pdf");
      } else if (this.pdf?.status == "timeout") {
        return this.$t("components.reports.pdf_tab.timeout_polling");
      } else if (this.pdf?.document?.content_hash === "error") {
        return this.$t(
          "components.reports.pdf_tab.updating_pdf_notice.completed_with_error"
        );
      }

      return null;
    },

    showCardHeader() {
      return this.controllerAction === "edit" || this.pdfCreationInProgress;
    },
  },

  watch: {
    report() {
      this.fetchReportPdf(this.report);
    },
  },

  async mounted() {
    await this.fetchReportPdf(this.report);

    if (!this.contentHashMatching || this.pdf.status === "not_found") {
      this.requestPdf();
    }
  },

  methods: {
    ...reportHelpers.mapActions([
      "fetchReportPdf",
      "requestReportPdfGeneration",
    ]),

    ...reportHelpers.mapMutations(["setPdfStatus"]),

    requestPdf() {
      this.requestReportPdfGeneration({ report: this.report, flash: true });
    },

    handleCallbackStatus(status) {
      this.setPdfStatus({ report: this.report, status: status });

      if (status == "completed") {
        this.fetchReportPdf(this.report);
      }
    },

    openDocumentModal() {
      this.$beModal.show(`document-modal-${this.report.id}`);
    },
  },
};
</script>
