<template>
  <be-table
    :items="memberships"
    :fields="activeFields"
    :hide-pagination="noPagination"
  >
    <template #action="{ item }">
      <template v-if="item.reinvitable">
        <i
          v-if="item.user.email_inactivated"
          v-be-tooltip="$t('models.membership.tooltips.email_inactivated')"
          class="fa-duotone fa-triangle-exclamation text-warning"
        />

        <be-button
          v-else
          v-be-tooltip="$t('models.membership.tooltips.reinvite_not_accepted')"
          variant="primary"
          size="sm"
          :disabled="isReminded(item.id)"
          icon="fa-rotate-right"
          inline
          @click="reinvite(item)"
        />
      </template>

      <template v-else-if="item.user.email_inactivated">
        <be-button
          v-be-tooltip="$t('models.membership.tooltips.reactivate')"
          variant="outline-primary"
          size="sm"
          icon="fa-triangle-exclamation"
          icon-style="fa-duotone"
          inline
          @click="reactivate(item)"
        />
      </template>

      <div v-else class="text-center">
        <i
          v-if="item.user.id === currentUser.id"
          v-be-tooltip="$t('models.membership.tooltips.your_account')"
          :class="[
            'fas fa-user',
            { 'mr-1': item.primary_contact || item.user.has_mfa },
          ]"
        >
          <span class="sr-only">
            {{ $t("models.membership.tooltips.your_account") }}
          </span>
        </i>

        <i
          v-if="item.user.has_mfa"
          v-be-tooltip="$t('models.membership.tooltips.has_mfa')"
          :class="['fas fa-key', { 'mr-1': item.primary_contact }]"
        >
          <span class="sr-only">
            {{ $t("models.membership.tooltips.has_mfa") }}
          </span>
        </i>

        <i
          v-if="item.primary_contact"
          v-be-tooltip="$t('models.membership.tooltips.primary_contact')"
          class="fas fa-star text-warning"
        >
          <span class="sr-only">
            {{ $t("models.membership.tooltips.primary_contact") }}
          </span>
        </i>
      </div>
    </template>

    <template #avatar="{ item }">
      <user-avatar :user="item.user" />
    </template>

    <template #name="{ item }">
      <div>{{ item.user.name }}</div>

      <membership-subtitle :membership="item" class="small" />
    </template>

    <template #email="{ item }">
      <be-link :href="`mailto:${item.user.email}`">
        {{ item.user.email }}
      </be-link>
    </template>

    <template #created_at="{ item }">
      {{ dateIfPresent(item.created_at) }}
    </template>

    <template #deleted_at="{ item }">
      {{ dateIfPresent(item.deleted_at) }}
    </template>

    <template #policy_level="{ item }">
      {{ $t(`models.membership.policy_levels.${item.policy_level}`) }}
      <template v-if="item.expires_at">
        <i
          v-be-tooltip="
            $t('models.membership.will_expire_at', {
              date: $d(new Date(item.expires_at)),
            })
          "
          class="fal fa-clock text-muted ml-1"
        />
      </template>
    </template>

    <template #options="{ item }">
      <template v-if="canEdit(item)">
        <be-dropdown size="sm" ellipsis right>
          <be-dropdown-item @click="edit(item)">
            {{ $t("components.memberships.buttons.edit") }}
          </be-dropdown-item>

          <template v-if="item.policy.destroy">
            <be-dropdown-divider />

            <be-dropdown-item variant="danger" @click="destroy(item)">
              {{ $t("buttons.titles.remove") }}
            </be-dropdown-item>
          </template>
        </be-dropdown>
      </template>
    </template>
  </be-table>
</template>

<script>
import { mapGetters } from "vuex";

import MembershipSubtitle from "@/components/shared/MembershipSubtitle.vue";

export default {
  components: {
    MembershipSubtitle,
  },

  inject: ["originOptions"],

  props: {
    memberships: {
      type: Array,
      required: true,
    },

    fields: {
      type: Array,
      default: () => [],
    },

    noPagination: {
      type: Boolean,
      required: false,
      default: false,
    },

    skipConfirmations: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["edit", "destroy", "reactivate", "reinvite"],

  data() {
    return {
      availableFields: {
        action: { key: "action", label: "", class: "col-shrink text-center" },
        avatar: { key: "avatar", label: "", class: "col-shrink" },

        created_at: {
          key: "created_at",
          label: this.translateAttribute("membership", "created_at"),
          class: "col-shrink",
        },

        deleted_at: {
          key: "deleted_at",
          label: this.translateAttribute("membership", "removed_at"),
          class: "col-shrink",
        },

        email: {
          key: "email",
          label: this.translateAttribute("user", "email"),
        },

        name: { key: "name", label: this.translateAttribute("user", "name") },
        options: { key: "options", label: "", class: "shrink" },

        policy_level: {
          key: "policy_level",
          label: this.translateAttribute("membership", "policy_level"),
          class: "col-shrink",
        },
      },

      reminded: [],
    };
  },

  computed: {
    ...mapGetters({
      company: "company/getCompany",
      currentUser: "current_user/getUser",
    }),

    hasOriginOptions() {
      return (
        this.flipperFlag("origins_in_membership_form") &&
        this.originOptions.length > 0
      );
    },

    activeFields() {
      if (this.fields) {
        return this.fields
          .map((field) => this.availableFields[field])
          .filter((field) => field);
      } else {
        return [];
      }
    },

    isReminded() {
      return (membershipId) => {
        return this.reminded.some((reminded) => reminded == membershipId);
      };
    },
  },

  methods: {
    canEdit(membership) {
      return (
        (this.hasOriginOptions && membership.policy_level == "observer") ||
        (this.$platform.features.user_administration &&
          membership.policy.update)
      );
    },

    dateIfPresent(date) {
      if (date) {
        return this.$d(new Date(date), { format: "mini" });
      }
      return "-";
    },

    edit(membership) {
      this.$emit("edit", membership);
    },

    destroy(membership) {
      this.$emit("destroy", membership);
    },

    reinvite(membership) {
      this.$emit("reinvite", membership);
      this.reminded.push(membership.id);
    },

    reactivate(membership) {
      this.$emit("reactivate", membership);
    },
  },
};
</script>
