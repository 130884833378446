<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-auto">
        <be-form-group
          :label="$t('components.shared.export_modal.file_type')"
          label-for="file-type"
        >
          <be-form-radio-group
            id="file-type"
            v-model="exportType"
            :options="exportTypeOptions"
            class="input-height"
            label-class="pl-3 py-2"
            text-class="font-weight-normal"
            :responsive="false"
            alternative
          />
        </be-form-group>
      </div>

      <div v-if="showFilters" class="col-12 col-md">
        <be-form-group
          v-if="filterTypes.length > 0"
          :label="$t('components.shared.export_modal.filter')"
          label-for="filter-type"
        >
          <be-form-select
            id="filter-type"
            v-model="filterType"
            :options="filterTypeOptions"
            required
          />
        </be-form-group>
      </div>
    </div>

    <slot v-if="showFilters" name="filters" />

    <div class="mt-3">
      <be-button
        variant="primary"
        class="mt-2"
        :disabled="!exportType || (filterTypes.length > 0 && !filterType)"
        @click="$emit('export', { exportType, filterType })"
      >
        {{ $tc("buttons.titles.create_export", 1) }}
      </be-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    exportTypes: {
      type: Array,
      required: true,
    },

    filterTypes: {
      type: Array,
      required: false,
      default: () => [],
    },

    preselectedOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  emits: ["export"],

  data() {
    return {
      exportType: "",
      filterType: null,
    };
  },

  computed: {
    exportTypeOptions() {
      return this.exportTypes.map((type) => ({
        text: this.$t(
          `components.shared.export_modal.file_types.${this.getFileType(type)}`
        ),

        value: type,
      }));
    },

    filterTypeOptions() {
      return this.filterTypes.map((type) => ({
        label: this.$t(`components.shared.export_modal.filter_types.${type}`),
        value: type,
      }));
    },

    showFilters() {
      const filterableExportTypes = ["pdf", "excel"];
      const fileType = this.getFileType(this.exportType);
      return filterableExportTypes.includes(fileType);
    },
  },

  mounted() {
    this.resetForm();
  },

  methods: {
    resetForm() {
      this.exportType =
        this.preselectedOptions.exportType || this.exportTypeOptions[0].value;
      this.filterType =
        this.preselectedOptions.filterType || this.filterTypeOptions[0].value;
    },

    getFileType(type) {
      return type.split("_").slice(-1)[0];
    },
  },
};
</script>
