<template>
  <be-table :items="documents" :fields="fields">
    <template #icon="data">
      <span v-dompurify-html="getIconForFilename(data.item.filename)" />
    </template>

    <template #filename="data">
      <document-link
        :document-id="data.item.id"
        :filename="data.item.filename"
        :show-icon="false"
      />
    </template>
  </be-table>
</template>

<script>
import { getIconForFilename } from "@/vendor/font-awesome-filetypes";

export default {
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },

  computed: {
    fields() {
      return [
        {
          key: "icon",
          label: "",
          class: "col-shrink",
        },
        {
          key: "filename",
          label: this.$i18n.t("activerecord.attributes.document.filename"),
        },
        {
          key: "file_size",
          label: this.$i18n.t("activerecord.attributes.document.filesize"),
          class: "col-shrink",
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
        },
      ];
    },
  },

  methods: {
    getIconForFilename(filename) {
      return filename ? getIconForFilename(filename) : null;
    },
  },
};
</script>
