<template>
  <be-dropdown-item v-be-modal.export-tables-to-excel-modal>
    {{ $t("components.reports.export_tables_to_excel_modal.title") }}
  </be-dropdown-item>

  <be-modal
    id="export-tables-to-excel-modal"
    :title="$t('components.reports.export_tables_to_excel_modal.title')"
    ok-only
    ok-variant="light"
    :ok-title="$t('buttons.titles.close')"
    @shown="exportTablesToExcel"
  >
    <be-alert v-if="tables.length === 0" variant="info">
      {{ $t("components.reports.export_tables_to_excel_modal.no_tables") }}
    </be-alert>

    <div v-else-if="exportStatus === 'loading'" class="rounded p-3 bg-light">
      <div class="d-flex align-items-center py-2">
        <be-spinner>
          {{ $t("components.shared.export_modal.creating_new_export") }}
        </be-spinner>
      </div>
    </div>

    <div
      v-else-if="exportStatus === 'success'"
      class="d-flex align-items-center justify-content-between w-100 rounded bg-light p-3 mb-2"
    >
      <div class="d-flex align-items-center mr-2 py-1">
        <i :class="`fal fa-file-excel fa-lg text-muted ml-1 mr-2`" />

        {{ $t("buttons.titles.excel_export") }}
      </div>

      <div class="d-flex align-items-center">
        <be-button
          :href="exportFileUrl"
          variant="primary"
          class="text-nowrap"
          icon="fa-arrow-down-to-bracket"
        >
          {{ $t("buttons.titles.download") }}
        </be-button>
      </div>
    </div>

    <be-alert v-else variant="danger">
      {{ $t("components.shared.export_modal.export_failed") }}.

      <be-link @click="exportTablesToExcel">
        {{ $t("buttons.titles.try_again") }}?
      </be-link>
    </be-alert>
  </be-modal>
</template>

<script>
export default {
  props: {
    report: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      exportFileUrl: null,
      exportStatus: "loading",
    };
  },

  computed: {
    tables() {
      const reportTables = this.report.report_components
        .filter(
          (component) =>
            component.component_type === "report_table" &&
            !!component.description
        )
        .map((component) => ({
          origin: component.origin || "SELF",
          uuid: component.description,
        }));

      const financial_attachments = this.report.financial_attachments
        .filter((attachment) => !!attachment.configuration)
        .map((attachment) => ({
          origin: attachment.origin || "SELF",
          uuid: attachment.configuration,
        }));

      return reportTables.concat(financial_attachments);
    },
  },

  methods: {
    async exportTablesToExcel() {
      if (this.tables.length === 0) {
        return;
      }

      this.exportStatus = "loading";

      try {
        const res = await wzGetController().exportTablesToExcel(
          this.$activeFinancialsUuid,
          {
            date: Date.parse(this.report.period_end),
            tables: this.tables,
          }
        );

        if (res.status === 200) {
          this.exportFileUrl = res.data.result;
          this.exportStatus = "success";
        } else {
          this.exportStatus = "failed";
        }
      } catch (error) {
        this.handleError(error);
        this.exportStatus = "failed";
      }
    },
  },
};
</script>
