<template>
  <be-skeleton-table v-if="invited.length === 0" />

  <be-table v-else :fields="fields" :items="sortedInvited">
    <template #avatar="{ item }">
      <user-avatar :user="getUser(item.user_id) || item.name" />
    </template>

    <template #name="{ item }">
      {{ getUserName(item.user_id) || item.name }}

      <membership-subtitle :user-id="item.user_id" class="small" />
    </template>

    <template #email="{ item }">
      <be-link :href="`mailto:${getUserEmail(item.user_id)}`">
        {{ getUserEmail(item.user_id) }}
      </be-link>
    </template>

    <template #always_invited="{ item }">
      <i
        v-if="isOwner(item)"
        v-be-tooltip="$t('attributes.owner')"
        class="fal fa-user text-muted"
      />

      <i
        v-else-if="alwaysInvited.includes(item.user_id)"
        v-be-tooltip="
          $t(
            'components.reports.participants.always_invited_when_published_tooltip'
          )
        "
        class="fal fa-eye text-muted"
      />
    </template>

    <template #created_at="{ item }">
      <template v-if="!item.deleted_at && item.created_at">
        {{ $d(new Date(item.created_at), { format: "mini" }) }}
      </template>
    </template>

    <template #remind="{ item }">
      <be-button
        size="sm"
        variant="outline-secondary"
        @click="$emit('remind', item)"
      >
        {{
          !item.deleted_at && item.created_at
            ? $t("buttons.titles.remind")
            : $t("buttons.titles.invite")
        }}
      </be-button>
    </template>

    <template #options="{ item }">
      <be-button
        v-be-tooltip="{
          title: removeTooltip(item),
          disabled: !isOwner(item) && !alwaysInvited.includes(item.user_id),
        }"
        class="ml-1"
        size="sm"
        variant="danger"
        :disabled="isOwner(item) || alwaysInvited.includes(item.user_id)"
        icon="fa-times"
        @click="$emit('uninvite', item)"
      />
    </template>
  </be-table>
</template>

<script>
import MembershipSubtitle from "@/components/shared/MembershipSubtitle.vue";

export default {
  components: {
    MembershipSubtitle,
  },

  props: {
    invited: {
      type: Array,
      required: true,
    },

    owners: {
      type: Array,
      default: () => [],
    },

    alwaysInvited: {
      type: Array,
      default: () => [],
    },

    invitePolicy: {
      type: Boolean,
      required: true,
    },
  },

  emits: ["remind", "uninvite"],

  computed: {
    fields() {
      const fields = [
        {
          key: "avatar",
          label: "",
          class: "col-shrink",
        },
        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },
        {
          key: "email",
          label: this.translateAttribute("user", "email"),
        },
        {
          key: "always_invited",
          label: "",
          class: "col-shrink",
        },
        {
          key: "created_at",
          label: this.translateAttribute("invitation", "created_at"),
          class: "col-shrink",
        },
      ];

      if (this.invitePolicy) {
        fields.push({
          key: "remind",
          label: "",
          class: "col-shrink text-center",
        });

        fields.push({
          key: "options",
          label: "",
          class: "col-shrink text-center",
        });
      }

      return fields;
    },

    sortedInvited() {
      let owners = [];
      let alwaysInvited = [];
      let others = [];

      this.invited.forEach((invitation) => {
        if (this.isOwner(invitation)) {
          owners.push(invitation);
        } else if (this.alwaysInvited.includes(invitation.user_id)) {
          alwaysInvited.push(invitation);
        } else {
          others.push(invitation);
        }
      });

      return owners.concat(alwaysInvited).concat(others);
    },
  },

  methods: {
    isOwner(invitation) {
      return this.owners.some((user) => user.id === invitation.user_id);
    },

    removeTooltip(invitation) {
      return this.isOwner(invitation)
        ? this.$t("components.reports.participants.remove_owner_tooltip")
        : this.$t("components.reports.participants.remove_tooltip");
    },

    membershipVia(user_id) {
      return this.getMembershipByUserId(user_id)?.via;
    },
  },
};
</script>
